import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ssiApi from '../../ssiApi.js';
import Navbar from '../../Navbar';
import Footer from '../Footer/Footer';
import './admin.css';
import axios from 'axios';
import studentPhoto from '../../img/studentIcon.png'
import DocumentTable from './DocumentTable';
import DocumentViewer from './DocumentViewer.js';
import ReactDOMServer from 'react-dom/server';
import ReactDOM from 'react-dom'; // Import ReactDOM
import adminBack from '../../img/auditBanerImg.jpeg'
import CertTable from './certPage.js'
import LicenseTable from './licensePage.js'

import { JsonToTable } from "react-json-to-table";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocation, faPhone, faEnvelope, faEllipsisVertical, faIdBadge, faFilePdf, faImage, faShare, faQuestionCircle, faArrowDown, faCaretDown, faPen, faUser, faMedal, faArrowUpRightFromSquare, faCheckCircle, faBitcoinSign, faBell, faHome, faList, faCalendar, faRightFromBracket, faArrowLeft, faEye, faCheck, faCross, faClose } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagramSquare, faLinkedin, faYoutubeSquare, faTwitterSquare, faWhatsapp, faFacebookSquare, faWhatsappSquare, faLinkedinIn, faTwitter, faEmber, faBitcoin } from '@fortawesome/free-brands-svg-icons'


const ssiBaseUrl = "https://ssi-test.myearth.id";
const authorizationKey = "01a41742-aa8e-4dd6-8c71-d577ac7d463c";

const privateKey = "6mf4ZKu2ts0V4Dr0sS+YbXYveZOyDezzTJUqa5Ls29BHN4jBTY5we/WW0CgmuNGb3PBvdqqJPhDF2YCMnF4faw==";
const publicKey = "RzeIwU2OcHv1ltAoJrjRm9zwb3aqiT4QxdmAjJxeH2s=";
const issuerDID = "did:earthid:testnet:2utZGEuzWe8ds5Jaxd7KdMD7bwKMm8QiRKnS2t4Q8Dve;earthid:testnet:fid=0.0.15057499";
const userDID = "did:earthid:testnet:9WzSyPSBkbUQVktjGxmvKAzbTNJCs1PFqo2fQ6BnuHiE;earthid:testnet:fid=0.0.15057499";

const MySwal = withReactContent(Swal);

const SidebarButton = ({ label, icon, onClick, isSelected, customClassName }) => {
    return (
        <button
            className={`sidebarbtn ${isSelected ? 'selected' : ''} ${customClassName}`}
            onClick={onClick}
        >
            <FontAwesomeIcon className="bellIcon" icon={icon} />
            {label}
        </button>
    );
};

const AdminMarriage = () => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedButton, setSelectedButton] = useState('License List');
    const [selectedUserDetails, setSelectedUserDetails] = useState(null);
    const [showUserList, setShowUserList] = useState(true);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [selectedMetadata, setSelectedMetadata] = useState(null);
    const [selectedDocHash, setSelectedDocHash] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [numPages, setNumPages] = useState(0);
    const [users, setUsers] = useState([]);
    const [pdfs, setPdfs] = useState([]);
    const [vcs, setVcs] = useState([]);
    const [trainingvcs, setTrainingVCs] = useState([]);
    const [professionalvcs, setProfessionalVCs] = useState([]);
    const [onBoardingvcs, setOnBoardingVcs] = useState([]);
    const [idvcs, setIdVcs] = useState([]);
    const [uniqueReqType, setUniqueReqType] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [loading, setLoading] = useState(false); // Added loading state
    const [selectAll, setSelectAll] = useState(false); // New state variable for "Select All" checkbox
    //const [selectedMenuButton, setSelectedMenuButton] = useState('Home'); // Default to 'Home'
    const [statusList, setStatusList] = useState(
        uniqueReqType.map(() => "Pending") // Initial status for all requests
    );

    // useEffect(() => {
    //     fetchVcs();
    // }, []);

    const fetchUserList = async () => {
        try {
            const response = await fetch('https://publicdb.myearth.id/api/vcnew/RequestMarriageLicenseVc/companies');
            const responseData = await response.json();
            console.log('Response', responseData)
            if (!Array.isArray(responseData.response)) {
                throw new Error('Invalid response data format');
            }

            const uniqueUserNames = [...new Set(responseData.response.map((response) => response))];
console.log('Company Names', uniqueUserNames)
            setUsers(uniqueUserNames);
        } catch (error) {
            console.error('Error fetching user list:', error);
        }
    };

    useEffect(() => {
        if (selectedButton === 'License List') {
            fetchUserList(); // Fetch user list immediately when the page loads
        }
    }, [selectedButton]);

    const fetchDocs = async (userName) => {
        try {
            //setLoading(true); // Set loading state to true

            // Fetch the document CIDs for the given user
            const fetchDocsResponse = await fetch(`https://publicdb.myearth.id/api/docnew/company/${userName}`);
            const fetchDocsData = await fetchDocsResponse.json();
            console.log('User All Docs',fetchDocsData.response);

            if (!Array.isArray(fetchDocsData.response)) {
                throw new Error('Invalid response data format');
            }

            // Prepare the request body with the CIDs
            const requestBody = {
                response: fetchDocsData.response,
            };
            console.log(requestBody);

            // Fetch the files based on the CIDs
            // const fetchFilesResponse = await fetch('https://publicdb.myearth.id/api/doc/retrieveFiles', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(requestBody),
            // });

            // if (!fetchFilesResponse.ok) {
            //     throw new Error('Failed to fetch files');
            // }

            // const fetchFilesText = await fetchFilesResponse.json();
            // console.log(fetchFilesText.fileNames)

            // Set the array of document URLs to the state variable
            setPdfs(requestBody);

            setLoading(false); // Stop loading
        } catch (error) {
            console.error('Error fetching documents:', error);
            setLoading(false); // Stop loading
            // Display Swal alert instead of throwing an error
            // MySwal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: 'Error fetching degree documents. Please try again.',
            // });
        }
    };


    const fetchVcs = async (userName) => {
        try {
          // Fetch the document CIDs for the given user
          const fetchDocsResponse = await fetch(`https://publicdb.myearth.id/api/vcnew/company/${userName}/RequestMarriageLicenseVc`);
          const vcData = await fetchDocsResponse.json();
          console.log('API Response:', vcData.response);
      
          if (!Array.isArray(vcData.response)) {
            throw new Error('Invalid response data format');
          }
      
          // Separate the VCs into arrays based on requestType
          const idVcs = [];
          const projectVcs = [];
          const trainingVCs = [];
          const professionalVcs = [];
      
          vcData.response.forEach((vcObj) => {
            const vc = vcObj.vc; // Access the 'vc' field within the object
            if (vc.credentialSubject[0].requestType === 'BirthCertificate') {
              idVcs.push(vc);
            } else if (vc.credentialSubject[0].requestType === 'DeathCertificate') {
              projectVcs.push(vc);
            } else if (vcObj.type === 'RequestMarriageLicenseVc') {
              trainingVCs.push(vc);
            }
            // Add more conditions for additional categories if needed
          });
      
          // Set the arrays of VCs to the state variables
          setIdVcs(idVcs);
          setOnBoardingVcs(projectVcs);
          setTrainingVCs(trainingVCs);
          setProfessionalVCs(professionalVcs);

          

          const uniqueRequestTypes = Array.from(
            new Set([
              ...idVcs.map((vc) => vc.credentialSubject[0].requestType),
              ...projectVcs.map((vc) => vc.credentialSubject[0].requestType),
              ...trainingVCs.map((vc) => vc.credentialSubject[0].requestType),
              ...professionalVcs.map((vc) => vc.credentialSubject[0].requestType),
            ])
          );

          setUniqueReqType(uniqueRequestTypes)
      
          console.log('API Response ID:', idVcs);
          console.log('API Response project:', projectVcs);
          console.log('API Response training:', trainingVCs);
          console.log('API Response prof:', professionalVcs);
      
          // Set the array of VCs to the state variable
          setVcs(vcData.response);
      
        } catch (error) {
          console.error('Error fetching documents:', error);
          // Handle the error, e.g., display an error message
        }
      };



    const issueCertificatesForAllUsers = async () => {
        try {
            setLoading(true); // Set loading state to true

            // Show loading Swal alert
            const loadingSwal = MySwal.fire({
                title: 'Issuing Certificates',
                html: 'Please wait while certificates are being generated...',
                allowOutsideClick: false,
                showConfirmButton: false,
                onBeforeOpen: () => {
                    MySwal.showLoading();
                },
            });

            // Loop through each user and issue certificates
            for (const user of users) {
                //setSelectedUser({ userName: user }); // Set the selected user
                await handleGenerateCertificateAll(user); // Issue degree certificate
                await handleGenerateIDCertificateAll(user); // Issue ID certificate
            }

            // Close the loading Swal alert
            loadingSwal.close();

            setLoading(false); // Set loading state to false

            // Show success Swal alert after successful completion
            MySwal.fire({
                icon: 'success',
                title: 'Certificates Issued Successfully',
                text: 'Certificates have been issued successfully for all users.',
            });
        } catch (error) {
            console.error('Error issuing certificates:', error);
            setLoading(false); // Set loading state to false

            // Show error Swal alert if there is an error during certificate issuance
            MySwal.fire({
                icon: 'error',
                title: 'Certificate Issuance Failed',
                text: 'Error issuing certificates. Please try again.',
            });
        }
    };




    const handleUserClick = async (user) => {
        try {
            setLoading(true); // Set loading state to true
console.log('this is user:', user)
            const pdfs = await fetchDocs(user);
            const vcs = await fetchVcs(user);
            const userData = {
                id: user.id,
                userName: user,
                userDID: user.userDID,
                pdfs,
                vcs
            };

            setSelectedUser(userData);
            setLoading(false); // Set loading state to false
            setSelectedUserDetails(user);
            setSelectedDocument(null);
            setSelectedMetadata(null);
            setSelectedId(null)

        } catch (error) {
            console.error('Error fetching user documents:', error);
        }
    };

    const handleDocumentView = (document, metaData, id, docHash) => {
        setSelectedDocument(document);
        setSelectedMetadata(metaData)
        setSelectedId(id)
        setSelectedDocHash(docHash)
        setShowUserList(false);
      };
      
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handleGenerateCertificate = async (index, requestType) => {
        if (selectedUser) {
            try {
                const loadingSwal = MySwal.fire({
                    title: 'Generating Certificate',
                    html: 'Please wait while the certificate is being generated...',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    onBeforeOpen: () => {
                        MySwal.showLoading();
                    },
                });

                if (requestType == 'BirthCertificate') {
                    const documentMetadata = [...idvcs];
                    console.log('NewDocData',documentMetadata)
                    await createVC(documentMetadata);
                  } else if (requestType == 'DeathCertificate') {
                    const documentMetadata = [...onBoardingvcs];
                    console.log('NewDocData',documentMetadata)
                    await createVC(documentMetadata);
                  } else if (requestType == 'MarriageCertificate') {
                    const documentMetadata = [...trainingvcs];
                    console.log('NewDocData',documentMetadata)
                    await createVC(documentMetadata);
                  } else if (requestType == 'MarriageLicense') {
                    const documentMetadata = [trainingvcs[0]];
                    console.log('NewDocData',documentMetadata)
                    await createVC(documentMetadata);
                    
                  }

                
                //await createUserSignature(documentMetadata);
                const updatedStatusList = [...statusList];
                updatedStatusList[index] = "Issued";
                setStatusList(updatedStatusList);

                loadingSwal.close();

                console.log('Certificate generated successfully!');
                MySwal.fire({
                    icon: 'success',
                    title: 'Certificate Generated',
                    text: 'Certificate generated successfully!',
                    showCancelButton: true,
                    confirmButtonText: 'View',
                }).then((result) => {
                    if (result.isConfirmed) {
                        let certificateUrl = '';
                      
                        if (requestType === 'BirthCertificate') {
                          certificateUrl = `/certificate/birth/${selectedUser.userName}`;
                        } else if (requestType === 'DeathCertificate') {
                          certificateUrl = `/certificate/death/${selectedUser.userName}`;
                        } else if (requestType === 'MarriageCertificate') {
                          certificateUrl = `/v1/certificate/marriage/${selectedUser.userName}`;
                        }else if (requestType === 'MarriageLicense') {
                            certificateUrl = `/v1/license/marriage/${selectedUser.userName}`;
                          }
                      
                        const newTabWindow = window.open(certificateUrl, '_blank');
                        newTabWindow.focus();
                      }
                });
            } catch (error) {
                console.error('Error generating certificate:', error);
                MySwal.fire({
                    icon: 'error',
                    title: 'Certificate Generation Failed',
                    text: 'Error generating certificate. Please try again.',
                });
            }
        }
    };

    // const handleGenerateCertificate = async () => {
    //     if (selectedUser) {
    //         try {
    //             const loadingSwal = MySwal.fire({
    //                 title: 'Generating Certificate',
    //                 html: 'Please wait while the certificate is being generated...',
    //                 allowOutsideClick: false,
    //                 showConfirmButton: false,
    //                 onBeforeOpen: () => {
    //                     MySwal.showLoading();
    //                 },
    //             });

    //             const documentMetadata = [...onBoardingvcs];
    //             //await createUserSignature(documentMetadata);
    //             await createVC(documentMetadata);

    //             loadingSwal.close();

    //             console.log('Certificate generated successfully!');
    //             MySwal.fire({
    //                 icon: 'success',
    //                 title: 'Certificate Generated',
    //                 text: 'Certificate generated successfully!',
    //                 showCancelButton: true,
    //                 confirmButtonText: 'View Certificate',
    //             }).then((result) => {
    //                 if (result.isConfirmed) {
    //                     const certificateUrl = `/certificate/degree/${selectedUser.userName}`; // Use the selected user's userName here
    //                     const newTabWindow = window.open(certificateUrl, '_blank');
    //                     newTabWindow.focus();
    //                 }
    //             });
    //         } catch (error) {
    //             console.error('Error generating certificate:', error);
    //             MySwal.fire({
    //                 icon: 'error',
    //                 title: 'Certificate Generation Failed',
    //                 text: 'Error generating certificate. Please try again.',
    //             });
    //         }
    //     }
    // };

    const handleGenerateIDCertificate = async () => {
        if (selectedUser) {
            try {
                const loadingSwal = MySwal.fire({
                    title: 'Generating Certificate',
                    html: 'Please wait while the certificate is being generated...',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    onBeforeOpen: () => {
                        MySwal.showLoading();
                    },
                });

                const documentMetadata = [...idvcs];
                //await createUserSignature(documentMetadata);
                await createIDVC(documentMetadata);

                loadingSwal.close();

                console.log('Certificate generated successfully!');
                MySwal.fire({
                    icon: 'success',
                    title: 'Certificate Generated',
                    text: 'Certificate generated successfully!',
                    showCancelButton: true,
                    confirmButtonText: 'View Certificate',
                }).then((result) => {
                    if (result.isConfirmed) {
                        const certificateUrl = `/certificate/student-ID/${selectedUser.userName}`; // Use the selected user's userName here
                        const newTabWindow = window.open(certificateUrl, '_blank');
                        newTabWindow.focus();
                    }
                });
            } catch (error) {
                console.error('Error generating certificate:', error);
                MySwal.fire({
                    icon: 'error',
                    title: 'Certificate Generation Failed',
                    text: 'Error generating certificate. Please try again.',
                });
            }
        }
    };


    const handleGenerateCertificateAll = async (user) => {

        try {
            const loadingSwal = MySwal.fire({
                title: 'Generating Certificate',
                html: 'Please wait while the certificate is being generated...',
                allowOutsideClick: false,
                showConfirmButton: false,
                onBeforeOpen: () => {
                    MySwal.showLoading();
                },
            });

            const documentMetadata = [...onBoardingvcs];
            //await createUserSignature(documentMetadata);
            await createVCAll(documentMetadata, user);

            loadingSwal.close();

            console.log('Certificate generated successfully!');
            MySwal.fire({
                icon: 'success',
                title: 'Certificate Generated',
                text: 'Certificate generated successfully!',
                showCancelButton: true,
                confirmButtonText: 'View Certificate',
            }).then((result) => {
                if (result.isConfirmed) {
                    const certificateUrl = `/certificate/degree/${user}`; // Use the selected user's userName here
                    const newTabWindow = window.open(certificateUrl, '_blank');
                    newTabWindow.focus();
                }
            });
        } catch (error) {
            console.error('Error generating certificate:', error);
            MySwal.fire({
                icon: 'error',
                title: 'Certificate Generation Failed',
                text: 'Error generating certificate. Please try again.',
            });
        }

    };

    const handleGenerateIDCertificateAll = async (user) => {

        try {
            const loadingSwal = MySwal.fire({
                title: 'Generating Certificate',
                html: 'Please wait while the certificate is being generated...',
                allowOutsideClick: false,
                showConfirmButton: false,
                onBeforeOpen: () => {
                    MySwal.showLoading();
                },
            });

            const documentMetadata = [...idvcs];
            //await createUserSignature(documentMetadata);
            await createIDVCAll(documentMetadata, user);

            loadingSwal.close();

            console.log('Certificate generated successfully!');
            MySwal.fire({
                icon: 'success',
                title: 'Certificate Generated',
                text: 'Certificate generated successfully!',
                showCancelButton: true,
                confirmButtonText: 'View Certificate',
            }).then((result) => {
                if (result.isConfirmed) {
                    const certificateUrl = `/certificate/student-ID/${user}`; // Use the selected user's userName here
                    const newTabWindow = window.open(certificateUrl, '_blank');
                    newTabWindow.focus();
                }
            });
            setSelectedUser(null);
        } catch (error) {
            console.error('Error generating certificate:', error);
            MySwal.fire({
                icon: 'error',
                title: 'Certificate Generation Failed',
                text: 'Error generating certificate. Please try again.',
            });
        }

    };


    //SSI APis

    // SSI Apis
    // const createUserSignature = async (documentMetadata) => {
    //     try {
    //         const userDid = await createUserDid();
    //         const payload = {
    //             payload: {
    //                 credentialSubject: {
    //                     id: userDid,
    //                     vcsArray: documentMetadata,
    //                 },
    //             },
    //         };

    //         const config = {
    //             method: 'post',
    //             url: `${ssiBaseUrl}/api/user/sign?issuerDID=${issuerDID}`,
    //             headers: {
    //                 'X-API-KEY': authorizationKey,
    //                 privateKey,
    //                 'Content-Type': 'application/json',
    //             },
    //             data: JSON.stringify(payload),
    //         };

    //         const response = await axios.request(config);
    //         console.log(response.data)
    //         return response.data.Signature;
    //     } catch (error) {
    //         console.log(error);
    //         throw error;
    //     }
    // };


    const createIDVC = async (documentMetadata, user) => {
        try {
            //const signature = await createUserSignature(documentMetadata);
            const data = {
                schemaName: 'StudentVCSchema:1',
                isEncrypted: false,
                dependantVerifiableCredential: [],
                credentialSubject: {
                    vcsArray: documentMetadata
                },
            };

            const config = {
                method: 'post',
                url: `${ssiBaseUrl}/api/issuer/verifiableCredential?isCryptograph=false&downloadCryptograph=false`,
                headers: {
                    'X-API-KEY': authorizationKey,
                    did: userDID,
                    publicKey,
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(data),
            };

            const response = await axios.request(config);
            console.log('VC response', response.data.data.verifiableCredential)
            const verifiableCredential = response.data.data.verifiableCredential;
            // Store the VC using the storeVC API
            await storeIDVC(user, userDID, verifiableCredential);
            const certName = 'Student ID'
            handleAddUserCertList(selectedUser.userName, certName)
            //return verifiableCredential

        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const createIDVCAll = async (documentMetadata, user) => {
        try {
            //const signature = await createUserSignature(documentMetadata);
            const data = {
                schemaName: 'StudentVCSchema:1',
                isEncrypted: false,
                dependantVerifiableCredential: [],
                credentialSubject: {
                    vcsArray: documentMetadata
                },
            };

            const config = {
                method: 'post',
                url: `${ssiBaseUrl}/api/issuer/verifiableCredential?isCryptograph=false&downloadCryptograph=false`,
                headers: {
                    'X-API-KEY': authorizationKey,
                    did: userDID,
                    publicKey,
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(data),
            };

            const response = await axios.request(config);
            console.log('VC response', response.data.data.verifiableCredential)
            const verifiableCredential = response.data.data.verifiableCredential;
            // Store the VC using the storeVC API
            await storeIDVCAll(user, userDID, verifiableCredential);

            //return verifiableCredential

        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const createVC = async (documentMetadata, user) => {
        console.log('DocumentMetaData', documentMetadata)
        try {
            //const signature = await createUserSignature(documentMetadata);
            const data = {
                schemaName: 'MarriageLicenseVC:1',
                isEncrypted: false,
                dependantVerifiableCredential: [],
                credentialSubject: {
                    issuedBy: "EarthID",
                    partner1: documentMetadata[0].credentialSubject[0].partner1,
                    partner2: documentMetadata[0].credentialSubject[0].partner2,
                    dateOfMarriage: new Date().toISOString()
                },
            };

            const config = {
                method: 'post',
                url: `${ssiBaseUrl}/api/issuer/verifiableCredential?isCryptograph=false&downloadCryptograph=false`,
                headers: {
                    'X-API-KEY': authorizationKey,
                    did: userDID,
                    publicKey,
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(data),
            };

            const response = await axios.request(config);
            console.log('VC response', response.data.data.verifiableCredential)
            const verifiableCredential = response.data.data.verifiableCredential;
            // Store the VC using the storeVC API
            await storeVC(user, userDID, verifiableCredential, "MarriageLicense");
            console.log('Request Type', verifiableCredential.credentialSubject.requestType)
            
            //return verifiableCredential

        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const createVCAll = async (documentMetadata, user) => {
        try {
            //const signature = await createUserSignature(documentMetadata);
            const data = {
                schemaName: 'StudentVCSchema:1',
                isEncrypted: false,
                dependantVerifiableCredential: [],
                credentialSubject: {
                    vcsArray: documentMetadata
                },
            };

            const config = {
                method: 'post',
                url: `${ssiBaseUrl}/api/issuer/verifiableCredential?isCryptograph=false&downloadCryptograph=false`,
                headers: {
                    'X-API-KEY': authorizationKey,
                    did: userDID,
                    publicKey,
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(data),
            };

            const response = await axios.request(config);
            console.log('VC response', response.data.data.verifiableCredential)
            const verifiableCredential = response.data.data.verifiableCredential;
            // Store the VC using the storeVC API
            await storeVCAll(user, userDID, verifiableCredential);

            //return verifiableCredential

        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const createUserDid = async () => {
        // Implement the logic to create a user DID (if required)
        // For now, returning a placeholder value
        return 'did:earthid:testnet:9WzSyPSBkbUQVktjGxmvKAzbTNJCs1PFqo2fQ6BnuHiE;earthid:testnet:fid=0.0.15057499';
    };



    //SSI Apis
    const storeIDVC = async (user, userDID, verifiableCredential) => {
        try {
            const prefix = 'IDCertificate'; // Replace with your desired prefix
            const userNamee = `${prefix}${selectedUser.userName}`;
            console.log('UserName', userNamee)
            const payload = {
                company: userNamee,
                type: 'CertificateVc',
                vc: verifiableCredential,
            };

            const config = {
                method: 'post',
                //url: 'https://publicdb.myearth.id/api/vc/storeVC',
                url: 'https://publicdb.myearth.id/api/vcnew/store',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(payload),
            };

            const response = await axios.request(config);
            console.log('Store VC response:', response.data);
            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const storeIDVCAll = async (user, userDID, verifiableCredential) => {
        try {
            const prefix = 'IDCertificate'; // Replace with your desired prefix
            const userNamee = `${prefix}${user}`;
            console.log('UserName', userNamee)
            const payload = {
                company: userNamee,
                type: 'CertificateVc',
                vc: verifiableCredential,
            };

            const config = {
                method: 'post',
                //url: 'https://publicdb.myearth.id/api/vc/storeVC',
                url: 'https://publicdb.myearth.id/api/vcnew/store',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(payload),
            };

            const response = await axios.request(config);
            console.log('Store VC response:', response.data);
            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const storeVC = async (user, userDID, verifiableCredential, requestType) => {
        try {
            let prefix = '';

if (requestType === 'BirthCertificate') {
  prefix = 'BirthCertificate';
  const certName = 'Birth Certificate'
            handleAddUserCertList(selectedUser.userName, certName)
} else if (requestType === 'MarriageLicense') {
  prefix = 'MarriageLicense';
  const certName = 'Marriage License'
            handleAddUserCertList(selectedUser.userName, certName)
} else if (requestType === 'MarriageCertificate') {
  prefix = 'MarriageCertificate';
  const certName = 'Marriage Certificate'
            handleAddUserCertList(selectedUser.userName, certName)
}  // Replace with your desired prefix
            const userNamee = `${prefix}${selectedUser.userName}`;
            console.log('Request Type:', requestType);
            console.log('Prefix:', prefix);
            console.log('UserName:', selectedUser.userName);
            console.log('Resulting UserName:', userNamee);
            const payload = {
                company: userNamee,
                type: 'CertificateVc',
                vc: verifiableCredential,
            };

            const config = {
                method: 'post',
                //url: 'https://publicdb.myearth.id/api/vc/storeVC',
                url: 'https://publicdb.myearth.id/api/vcnew/store',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(payload),
            };

            const response = await axios.request(config);
            console.log('Store VC response:', response.data);
            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const storeVCAll = async (user, userDID, verifiableCredential) => {
        try {
            const prefix = 'OnBoardingCertificate'; // Replace with your desired prefix
            const userNamee = `${prefix}${user}`;
            console.log('UserName', userNamee)
            const payload = {
                company: userNamee,
                type: 'CertificateVc',
                vc: verifiableCredential,
            };

            const config = {
                method: 'post',
                //url: 'https://publicdb.myearth.id/api/vc/storeVC',
                url: 'https://publicdb.myearth.id/api/vcnew/store',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(payload),
            };

            const response = await axios.request(config);
            console.log('Store VC response:', response.data);
            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };



    const verifyVC = async (credential) => {
        try {

            //const signature = await createUserSignature();

            const data = { "credentials": credential }
            console.log(data)
            const config = {
                method: 'post',
                url: ssiBaseUrl + "/api/verifier/verify?zpkValidation=false",
                headers: {
                    'X-API-KEY': authorizationKey,
                    'publicKey': publicKey,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            };

            const response = await axios.request(config);
            console.log(JSON.stringify(response.data));

            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }



    const handleVerifyDoc = async (credential) => {
        // Display 'Verifying...' Swal alert
        Swal.fire({
            title: 'Verifying...',
            text: 'Please wait while the request is being verified.',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });

        try {
            // Perform the verification process
            const response = await verifyVC(credential); // Replace 'verifyVC()' with the actual verification function

            // Hide the 'Verifying...' Swal alert
            Swal.close();

            // Show success Swal alert if verification is successful
            if (response.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Verification Successful',
                    text: 'The request has been verified successfully.',
                });
            } else {
                // Show error Swal alert if verification fails
                Swal.fire({
                    icon: 'error',
                    title: 'Verification Failed',
                    text: 'The document verification failed.',
                });
            }

            // Return the verification result
            return response;
        } catch (error) {
            // Hide the 'Verifying...' Swal alert
            Swal.close();

            // Show error Swal alert if an error occurs during verification
            Swal.fire({
                icon: 'error',
                title: 'Verification Error',
                text: 'An error occurred during the document verification.',
            });

            // Throw the error to be caught by the calling function
            throw error;
        }
    };


    const handleAddUserCertList = (userName, certName) => {
       
        //const certName = 'Audit Certificate'
            // Prepare the data for the POST request
            const data = { userName: userName,
                 certName: certName };
        console.log('Add user Data:', data)
            // Make the POST request to add the user certification
            fetch('https://publicdb.myearth.id/api/certificate/addUserCert', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
              .then(response => response.json())
              .then(data => {
                console.log('User certification added:', data);
                // Clear the input fields after successful submission
               
              })
              .catch(error => console.error('Error adding user certification:', error));
          };

    const toggleView = () => {
        setShowTable((prevShowTable) => !prevShowTable);
    };

    //SSI Apis End

    const handleButtonClick = (buttonLabel) => {
        setSelectedButton(buttonLabel);
    };

    const handleBackToUserList = () => {
        setSelectedUser(false);
        setSelectedDocument(null);
        setSelectedMetadata(null);
        setSelectedId(null)
      };


    // const renderUserList = () => {
    //     return users.map((user) => (
    //         <div
    //             key={user}
    //             onClick={() => handleUserClick(user)}
    //             className={`user-item ${selectedUser && selectedUser.userName === user ? 'selected' : ''}`}
    //         >
    //             {user}
    //         </div>
    //     ));
    // };

console.log('Selected', selectedMetadata)
const getMetaDataForSelectedDocument = (selectedDocument) => {
    const documentData = pdfs.response.find(pdf => pdf.doc === selectedDocument);
    console.log(documentData)
    return documentData ? documentData.metaData : null;
  };
      
    const handleLogout = () => {
        window.location.href = "/";
    }

    // Function to handle rejection
    const handleRejectRequest = (index) => {
        // Show loading Swal for 2 seconds
        Swal.fire({
            title: "Processing...",
            text: "Please wait while we reject the request...",
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            willOpen: () => {
                Swal.showLoading();
            },
        });
    
        setTimeout(() => {
            // Update status to "Revoked"
            const updatedStatusList = [...statusList];
            updatedStatusList[index] = "Revoked";
            setStatusList(updatedStatusList);
    
            // Show final Swal alert
            Swal.fire({
                title: "Request Rejected",
                text: "The certificate request has been revoked.",
                icon: "success",
                confirmButtonText: "OK",
            });
        }, 2000); // Wait for 2 seconds before showing final alert
    };
    
    // const DocumentViewerContainer = () => {
    //     const [document, setDocument] = useState(null);
      
    //     return document ? (
    //       <DocumentViewer document={document} onClose={() => setDocument(null)} />
    //     ) : null;
    //   };

      
//     const renderPDFViewer = () => {
      
//         if (!selectedUser) {
//             return <p className="no-user"></p>;
//         }

//         if (loading) {
//             return <p className="loading">Documents Loading...</p> // Render loading message
//         }

//         return (
//             <div className="pdf-viewer">
//                 <div className="pdf-header">
//                     <h3>Student: {selectedUser.userName}</h3>
//                     <div className="action-buttons">
//                         <button className="issue-button" onClick={handleGenerateCertificate}>
//                             Issue Degree Certificate
//                         </button>
//                         <button className="issue-button" onClick={handleGenerateIDCertificate}>
//                             Issue ID Certificate
//                         </button>
//                     </div>
//                 </div>
//                 <div className="pdf-container">
//                     {pdfs.map((pdf, index) => (
//                         <div key={`document_${index}`} className="document-container">
//                             <h3 className='fileName'>{pdf}</h3>
//                             {/* <h4>Document {index + 1}</h4> */}
//                             <div className="metadata-container">
//                                 {/* <p className="metadata-label">{pdf}</p> */}
//                                 {/* <p className="metadata-value">Metadata value</p> */}
//                             </div>
//                             <div className='docView'>
//                                 <h3>Preview:</h3>
//                                 <Document file={`https://publicdb.myearth.id/${pdf}`} onLoadSuccess={onDocumentLoadSuccess}>
//                                     {Array.from(new Array(numPages), (el, pageIndex) => (
//                                         <Page
//                                             key={`page_${index + 1}_${pageIndex + 1}`}
//                                             pageNumber={pageIndex + 1}
//                                             width={800}
//                                         />
//                                     ))}
//                                 </Document>
//                             </div>
//                         </div>
//                     ))}
//                     {/* Render verifiable credentials */}
//                     {/* {vcs.map((vc, index) => (
//   <div key={`vc_${index}`} className="vc-container">
//     <div className="row">
//       <h3 className="dgtProof">{vc.version == 'StudentIdVC:1' ? 'Student ID' : (vc.version == 'StudentDemoSchema:1' ? 'Student Document' : vc.version)} (VC):</h3>
//       {/* Toggle Button */}
//                     {/* <button className="changeViewbtn" onClick={toggleView}>
//         Change View
//       </button> */}
//                     {/* <button className="verifyBtn" onClick={() => handleVerifyDoc(vc)}>
//         Verify
//       </button> */}
//                     {/* </div>
//     {showTable ? ( */}
//                     {/* <pre>{JSON.stringify(vc, null, 2)}</pre>
//     ) : (
//       <pre> */}
//                     {/* <JsonToTable json={vc} />
//     </pre>
//     )} */}
//                     {/* <hr />
//   </div>
// ))} */}
//                 </div>
//             </div>
//         );
//     };

const extractPartnerNames = () => {
    if (!trainingvcs.length || !trainingvcs[0].credentialSubject.length) return null;

    const firstVC = trainingvcs[0].credentialSubject[0]; // First credentialSubject in trainingvcs
    const partner1 = firstVC.partner1;
    const partner2 = firstVC.partner2;

    return {
        partner1: `${partner1.partner1_firstName || partner1.firstName || "Partner1"} ${partner1.partner1_lastName || partner1.lastName || ""}`,
        partner2: `${partner2.partner2_firstName || partner2.firstName || "Partner2"} ${partner2.partner2_lastName || partner2.lastName || ""}`,
        details: {
            partner1,
            partner2
        }
    };
};

// Store extracted partner details
const partnerData = extractPartnerNames();


const handleViewPartnerDetails = (partner) => {
    if (!partnerData) return;

    const selectedPartner =
        partner === partnerData.partner1
            ? partnerData.details.partner1
            : partnerData.details.partner2;

    let isEditing = false;
    let editedValues = { ...selectedPartner }; // Copy of original values

    const formatKey = (key) => {
        return key
            .replace(/_/g, " ")
            .replace(/partner1\s?|partner2\s?/gi, "")
            .replace(/\b\w/g, (char) => char.toUpperCase());
    };

    // Function to render partner details
    const renderPartnerDetails = () => {
        return Object.entries(editedValues)
            .map(
                ([key, value]) => `
                <div style="display: flex; align-items: center; justify-content: space-between; 
                            padding: 10px 15px; border-bottom: 1px solid #ddd; font-size: 14px;">
                    <span style="font-weight: bold; color: #2C3E50; flex: 1; text-align: left; display: flex; align-items: center;">${formatKey(key)}:</span>
                    ${
                        isEditing
                            ? `<input type="text" id="edit-${key}" value="${value}" 
                                style="border: 1px solid #ccc; padding: 8px 10px; margin-top: 15px; font-size: 14px; width: 60%; height: 35px; 
                                display: flex; align-items: center; justify-content: center;" />`
                            : `<span style="color: #34495E; flex: 2; text-align: right; height: 35px; 
                        display: flex; align-items: center; justify-content: flex-end;">${value}</span>`
                    }
                </div>`
            )
            .join("");
    };
    
    

    // Function to show the Swal alert
    const showSwal = () => {
        Swal.fire({
            title: `<h3 style="color: #42145f; font-size: 22px; font-weight: bold; margin-bottom: 15px;">${partner} Details</h3>`,
            html: `
                <div style=" padding: 15px; border-radius: 10px; background: #F8F9FA; 
                            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); max-height: 500px; overflow-y: auto;">
                    ${renderPartnerDetails()}
                </div>
            `,
            showCancelButton: true,
            cancelButtonText: isEditing ? "Cancel" : "Close",
            confirmButtonText: isEditing ? "Save" : "Edit",
            confirmButtonColor: isEditing ? "#42145f" : "#42145f",
            customClass: {
                popup: "swal-custom-popup",
            },
            preConfirm: () => {
                if (isEditing) {
                    // Capture updated values
                    Object.keys(editedValues).forEach((key) => {
                        const inputElement = document.getElementById(`edit-${key}`);
                        if (inputElement) {
                            editedValues[key] = inputElement.value;
                        }
                    });
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                if (isEditing) {
                    // Save updated values and update partnerData
                    console.log("✅ Updated Partner Details:", editedValues);
                    partnerData.details[partner === partnerData.partner1 ? "partner1" : "partner2"] = { ...editedValues };
                }

                isEditing = !isEditing; // Toggle edit mode
                showSwal(); // Re-render Swal with updated state
            }
        });
    };

    showSwal(); // Initial Swal render
};



const handleViewReqVc = (requestType) => {
    let documentMetadata;

    if (requestType === 'MarriageLicense') {
        documentMetadata = trainingvcs.length > 0 ? trainingvcs[0] : null;
    }

    if (!documentMetadata) {
        Swal.fire({
            icon: "error",
            title: "No Data Found",
            text: "No Verifiable Credential found for this request type.",
        });
        return;
    }

    // Convert JSON object to formatted string
    const formattedJson = JSON.stringify(documentMetadata, null, 2);

    Swal.fire({
        title: ` <h3 style="color: #42145f; font-size: 22px; font-weight: bold; margin-bottom: 15px;">Digital Credential</h3>`,
        html: `
            <div style="text-align: left; padding: 15px; border-radius: 10px; background: #F8F9FA; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); font-size: 14px; white-space: pre-wrap; max-height: 500px; overflow-y: auto;">
                <pre style="white-space: pre-wrap; word-wrap: break-word; margin: 0; padding: 0;">${formattedJson}</pre>
            </div>
        `,
        showCancelButton: true,
        confirmButtonText: "Verify",
        cancelButtonText: "Close",
        confirmButtonColor: "#42145f",  // Verify Button Color
        cancelButtonColor: "#6c757d",  // Close Button Color
        width: "800px",
        customClass: {
            popup: "swal-custom-popup",
            title: "swal-custom-title",
            htmlContainer: "swal-custom-html",
        },
        
    }).then((result) => {
        if (result.isConfirmed) {
            handleVerifyDoc(documentMetadata); // Call verify function when clicked
        }
    });
};









    return (
        <>

            <div className="admin-container">
                <div className="row">

                    <div className="sidebar col-md-2">
                        <div className='stuProf'>
                            <div className="photo">
                                <img src={studentPhoto} alt="Student Photo" />
                            </div>
                            <div className='stuDentDiv'>
                                <div className='stuUserNameHead'>
                                    Admin Name
                                </div>
                                <div className='stuUserName'>
                                    John White
                                </div>
                            </div>

                        </div>
                        <div className='smallHr'>
                            <hr></hr>
                        </div>

                        {/* <SidebarButton
                            label="Home"
                            icon={faHome}
                            onClick={() => handleButtonClick('Home')}
                            isSelected={selectedButton === 'Home'}
                        /> */}

                        {/* <SidebarButton
                            label="License Request List"
                            icon={faList}
                            onClick={() => {
                                fetchUserList();
                                handleButtonClick('License List');
                            }}
                            isSelected={selectedButton === 'License List'}
                        />

                        <SidebarButton
                            label="Issued Licenses"
                            icon={faCalendar}
                            onClick={() => handleButtonClick('Issued Licenses')}
                            isSelected={selectedButton === 'Issued Licenses'}
                        /> */}

                        <SidebarButton
                            label="License Request List"
                            icon={faList}
                            onClick={() => {
                                fetchUserList();
                                handleButtonClick('License List');
                            }}
                            isSelected={selectedButton === 'License List'}
                        />

                        <SidebarButton
                            label="Issued License"
                            icon={faCalendar}
                            onClick={() => handleButtonClick('Issued License')}
                            isSelected={selectedButton === 'Issued License'}
                        />
                        <div className='smallHr'>
                            <hr></hr>
                        </div>

                        <SidebarButton
                            label="Help"
                            icon={faQuestionCircle}
                            onClick={() => {
                                handleButtonClick('Help');
                            }}
                            isSelected={selectedButton === 'Help'}
                        />

                        <SidebarButton
                            label="Logout Account"
                            icon={faRightFromBracket}
                            onClick={() => {
                                handleLogout()
                                handleButtonClick('Logout Account')
                            }}
                            isSelected={selectedButton === 'Logout Account'}
                            customClassName='redText'
                        />
                        {/* <button className='sidebarbtn' ><FontAwesomeIcon className='bellIcon' icon={faBell} /> Home</button>
                      
                        
                        <button className='sidebarbtn' onClick={fetchUserList}><FontAwesomeIcon className='bellIcon' icon={faBell} /> Student List</button>
                        
                        
                        <button className='sidebarbtn' ><FontAwesomeIcon className='bellIcon' icon={faBell} /> Issued Certificate</button>
                        */}

                    </div>
                    <div className="content col-md-10">
                        <div className='row miniNav'>
                            <div className='companyy'>
                                EarthID
                            </div>
                            <FontAwesomeIcon className='bellIcon' icon={faBell} />
                        </div>

                        {selectedButton === 'Home' && <div className='adminBack'>
                                <img src={adminBack}></img>
                            </div>}



{selectedButton === 'License List' && <div>
{users.length > 0 ? (
                            <>
                                
                               
                                {selectedUser ? (
                                    <>
                                     <div className="pdf-viewer">
                                        <div className='backBtnDiv'>
                                        <button className='backBtn' onClick={handleBackToUserList}><FontAwesomeIcon className='backIcon' icon={faArrowLeft} /> Back</button>
                                        </div>
                                     
                                        <div className='studentName'><span>Name:</span> {selectedUser.userName}</div>
                                        
                <div className='listTag'>Request List</div>
                <div className="user-tablee">
                                    <table className="user-table">
                                        <thead>
                                            <tr>
                                                <th>Sr No.</th>
                                                <th>Requested Certificate</th>
                                                <th>View</th>
                                                <th>Action</th>
                                                <th>Status</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
        {uniqueReqType.map((requestType, index) => {
            const status = statusList[index] || "Pending"; // Default to "Pending"

            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>Marriage License</td>
                    <td>
                    {status !== "Issued" && status !== "Revoked" && (
                        <FontAwesomeIcon
                            className="eyeIcon"
                            icon={faEye}
                            onClick={() => handleViewReqVc("MarriageLicense")}
                        />
                    )}
                    </td>
                    <td className='row'>
                        {/* Hide icons if status is "Issued" or "Revoked" */}
                        { status !== "Revoked" && (
                            <>
                                <FontAwesomeIcon
                                    onClick={() => handleGenerateCertificate(index, "MarriageLicense")}
                                    className="eyeIcon green-icon"
                                    icon={faCheck}
                                />
                                <FontAwesomeIcon
                                    onClick={() => handleRejectRequest(index)}
                                    className="eyeIcon red-icon"
                                    icon={faClose}
                                />
                            </>
                        )}
                    </td>
                    <td>
                        <span
                            className={`status-label ${
                                status === "Pending"
                                    ? "status-pending"
                                    : status === "Issued"
                                    ? "status-issued"
                                    : "status-revoked"
                            }`}
                        >
                            {status}
                        </span>
                    </td>
                </tr>
            );
        })}
    </tbody>
                                    </table>
                                </div>
                                <div className='listTag'>Partner Details</div>
                                {partnerData && (
    <div className="user-tablee">
        <table className="user-table">
            <thead>
                <tr>
                    <th>Sr No.</th>
                    <th>Partner Name</th>
                    <th>View</th>
                </tr>
            </thead>
            <tbody>
                {/* Row for Partner 1 */}
                <tr>
                    <td>1</td>
                    <td>{`${partnerData.partner1}`}</td>
                    <td>
                        <FontAwesomeIcon
                            className="eyeIcon"
                            icon={faEye}
                            onClick={() => handleViewPartnerDetails(partnerData.partner1)}
                        />
                    </td>
                </tr>

                {/* Row for Partner 2 */}
                <tr>
                    <td>2</td>
                    <td>{`${partnerData.partner2}`}</td>
                    <td>
                        <FontAwesomeIcon
                            className="eyeIcon"
                            icon={faEye}
                            onClick={() => handleViewPartnerDetails(partnerData.partner2)}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
)}

 

                                {/* <div className='listTag'>Document List</div>
                <DocumentTable pdfs={pdfs} onDocumentView={handleDocumentView} /> */}
                {/* <div className="pdf-header">
                    
                    <div className="action-buttons">
                        <button className="issue-button" onClick={() => handleGenerateCertificate('EmploymentCertificate')}>
                            Issue Employement Certificate
                        </button>
                        <button className="issue-button" onClick={() => handleGenerateCertificate('ProjectCompletionCertificate')}>
                            Issue Project Completion Certificate
                        </button>
                        <button className="issue-button" onClick={() => handleGenerateCertificate('TrainingCertificate')}>
                            Issue Training Certificate
                        </button>
                        <button className="issue-button" onClick={() => handleGenerateCertificate('ProfessionalMembershipCertificate')}>
                            Issue Professional Certificate
                        </button>
                    </div>
                </div> */}
            </div>
                                    </>
                                    
            ) : (
                <>
                <div className='listTag'>List</div>
                <div className="user-tablee">
                                    <table className="user-table">
                                        <thead>
                                            <tr>
                                                <th>Sr No.</th>
                                                <th>ID</th>
                                                <th>Partners</th>
                                                <th>View</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.map((user, index) => {
                                                const userID = `202300${index + 1}`;
                                                return (
                                                    <tr key={user}>
                                                        <td>{index + 1}</td>
                                                        <td>{userID}</td>
                                                        <td>
                                                            <span >
                                                                {user}
                                                            </span>
                                                        </td>
                                                        <td>
                <FontAwesomeIcon onClick={() => handleUserClick(user)} className="eyeIcon" icon={faEye} />
              </td>

                                                        {/* <td>
            <button className="review-btn" onClick={() => handleUserClick(user)}>Display Documents</button>
          </td> */}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='row'>
                                {/* <div className="issue-all-btn">
                                    <button onClick={issueCertificatesForAllUsers}>Issue Certificates to All Users</button>
                                </div> */}
                                {/* <div className="issue-all-btn">
                                    <button>Add Employees</button>
                                </div> */}
                                </div>
                                
                </>
            )}
                {selectedDocument && selectedMetadata && (
            //   <DocumentViewer
            //     document={`https://publicdb.myearth.id/${selectedDocument}`}
            //     onClose={() => setSelectedDocument(null)}
            //   />
            <div className="document-popup">
          <div className="document-popup-content">
            <DocumentViewer
              document={`https://publicdb.myearth.id/${selectedDocument}`}
              metaData={selectedMetadata}
              id={selectedId}
              docHash={selectedDocHash}
              onClose={() => setSelectedDocument(null)}
            />
          </div>
        </div>
            )}              
                               
                            </>

                        ) : (
                            <>
                <div className='listTag'>List</div>
                <div className="user-tablee">
                                    <table className="user-table">
                                        <thead>
                                            <tr>
                                                <th>Sr No.</th>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>View</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.map((user, index) => {
                                                const userID = `202300${index + 1}`;
                                                return (
                                                    <tr key={user}>
                                                        <td>{index + 1}</td>
                                                        <td>{userID}</td>
                                                        <td>
                                                            <span >
                                                                {user}
                                                            </span>
                                                        </td>
                                                        <td>
                <FontAwesomeIcon onClick={() => handleUserClick(user)} className="eyeIcon" icon={faEye} />
              </td>

                                                        {/* <td>
            <button className="review-btn" onClick={() => handleUserClick(user)}>Display Documents</button>
          </td> */}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='row'>
                                {/* <div className="issue-all-btn">
                                    <button onClick={issueCertificatesForAllUsers}>Issue Certificates to All Users</button>
                                </div> */}
                                {/* <div className="issue-all-btn">
                                    <button>Add Employees</button>
                                </div> */}
                                </div>
                                
                </>
                        )}
    </div>}
    {selectedButton === 'Issued License' && <>
<div className='listTag'>Issued Licenses</div>
<CertTable />
</>}

{selectedButton === 'Help' && <>
<div className='listTag'>Help Section</div>
</>}
                        
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default AdminMarriage;
import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './certificate.scss';
import logo from '../../img/EarthID Logo - Primary - BLACK.png'
import logoWhite from '../../Logo-EarthID-white.png'
import favicon from '../../img/EarthID FAVICON - PRIMARY.png'
import check from '../../img/check.png'
import ssiApi from '../../ssiApi.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useParams } from "react-router-dom";
import QRCode from 'qrcode.react';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, pdf } from '@react-pdf/renderer';
import CertificatePDF from './downloadCert';
import { saveAs } from 'file-saver'; // Import the file-saver library
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import BadgeImg from '../../img/badgeImg.jpeg'
import badgeIcon from '../../img/rosette.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faLocation, faPhone, faEnvelope, faEllipsisVertical, faIdBadge, faFilePdf, faImage, faShare, faQuestionCircle, faArrowDown, faCaretDown, faPen, faUser, faMedal, faArrowUpRightFromSquare, faCheckCircle, faBitcoinSign, faGlobe, faCross, faCircleXmark, faBuilding } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagramSquare, faLinkedin, faYoutubeSquare, faTwitterSquare, faWhatsapp, faFacebookSquare, faWhatsappSquare, faLinkedinIn, faTwitter, faEmber, faBitcoin, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, LineShareButton, LinkedinShareButton } from 'react-share';

import { JsonToTable } from "react-json-to-table";
import axios from 'axios';

const moment = require('moment');


const ssiBaseUrl = "https://ssi-test.myearth.id";
const authorizationKey = "01a41742-aa8e-4dd6-8c71-d577ac7d463c";

const privateKey = "6mf4ZKu2ts0V4Dr0sS+YbXYveZOyDezzTJUqa5Ls29BHN4jBTY5we/WW0CgmuNGb3PBvdqqJPhDF2YCMnF4faw==";
const publicKey = "geMiBNanS2G0apweNStndbsBkKdc8rjzPtbP4N6EApg=";
 const issuerDID = "did:earthid:testnet:EvU7xs7swFEYqANkFDQDvSP97EzaVmVv3E5K5L3uKMXk;earthid:testnet:fid=0.0.4243508";
const userDID = "did:earthid:testnet:DsvEjMRKhboDb7GGYRL2P3BpV5htGx9QB2HPzSWpw89J;earthid:testnet:fid=0.0.4243508";





const MarriageCert = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [responseData, setResponseData] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isVerificationLoading, setIsVerificationLoading] = useState([true, true, true, true, true]);
    const [verificationData, setVerificationData] = useState([]);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    const [showCertificate, setShowCertificate] = useState(true);
    //const [showJson, setShowJson] = useState(true);
    const {  userName, certType, certId } = useParams();
   // const userName1 = "subject"
     const issuer = "EarthID"



     const mockCredentials = {
        username: "EarthID",
        password: "12345"
    };

    useEffect(() => {
        // Show login popup when the component mounts
        if (!isLoggedIn) {
            showLoginPopup();
        }
    }, []);


    const showLoginPopup = () => {
        Swal.fire({
            title: "Login Required",
            html: `
                <input type="text" id="swal-username" class="swal2-input" placeholder="Username">
                <input type="password" id="swal-password" class="swal2-input" placeholder="Password">
            `,
            showCancelButton: false,
            confirmButtonText: "Login",
            allowOutsideClick: false,
            preConfirm: () => {
                const enteredUsername = document.getElementById('swal-username').value;
                const enteredPassword = document.getElementById('swal-password').value;

                if (enteredUsername !== mockCredentials.username || enteredPassword !== mockCredentials.password) {
                    Swal.showValidationMessage('Invalid Username or Password');
                }

                return { username: enteredUsername, password: enteredPassword };
            }
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoggedIn(true);
                setUsername(result.value.username);
                setPassword(result.value.password);
                fetchData();
            }
        });
    };


    const qrCodeUrl = `https://public.myearth.id/v1/certificate/marriage/${userName}`;

    const pdfContainerRef = useRef();


    const certificationName = 'CMS equIP Member Certificate';
    const certUrl = `https://public.myearth.id/v1/certificate/marriage/${userName}`;


    const shareSubject = `Exciting News! ${userName} Recognised as a ${issuer} equIP Member`;

const shareBody = `
We are excited to share that ${userName} Marrigae Certificate has been issued! 🎉

This verifiable certificate has been issued by ${issuer} in partnership with EarthID.
    
#${userName} #${issuer} #EarthID #Innovation
    
🔗 View certificate here:`;

    const shareBodyMail = `Dear Recipient,

We are excited to share that ${userName} Marrigae Certificate has been issued! 🎉

This verifiable certificate has been issued by ${issuer} in partnership with EarthID.

🔗 View certificate here: 
${certUrl}
        
#${userName} #${issuer} #EarthID #Innovation

Kind Regards,
${userName}`;

    const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(certUrl)}`;



const responseVC = [
    {
        "_id": "6698e120ffb96bf4d504417f",
        "company": "MilihouseVanHouten&LisaMarieSimpson",
        "type": "CertificateVc",
        "vc": {
            "@context": [
                "https://www.w3.org/2018/credentials/v1"
            ],
            "id": "MarriageCertificateVc:1:acc90eef-1a3a-45c3-b3c1-60d71a2fd422",
            "type": [
                "VerifiableCredential",
                "MarriageCertificateVc:1"
            ],
            "version": "MarriageCertificateVc:1",
            "credentialSchema": {
                "id": "http://ssi-test.myearth.id/schema/MarriageCertificateVc",
                "type": "JsonSchemaValidator2018"
            },
            "issuer": "did:earthid:testnet:EvU7xs7swFEYqANkFDQDvSP97EzaVmVv3E5K5L3uKMXk;earthid:testnet:fid=0.0.4243508",
            "credentialSubject": [
                {
                    "id": "did:earthid:testnet:F1nifyHDAQrxkLqXKU9yQf2VkQat9NDwuH7StuVST1rw;earthid:testnet:fid=0.0.4243508",
                    "docNumber": "018349",
                    "marriageDate": "01/08/2010",
                    "placeOfMarriage": "Springfield Station, Springfield",
                    "spouse1": "Milihouse Van Houten",
                    "spouse2": "Lisa Marie Simpson",
                    "spouse1DOB": "07/01/1980",
                    "spouse2DOB": "05/09/1982",
                    "spouse1Place": "Springfield",
                    "spouse2Place": "Springfield",
                    "spouse1State": "JF",
                    "spouse2State": "JF",
                    "issuanceAuthority": "Ordained Minister",
                    "issuedBy": "Timothy Lovejoy",
                    "issuancePlace": "Springfield",
                    "issuanceState": "Jefferson",
                    "witness1": "Homer J. Simpson",
                    "witness2": "Freddy Fredson",
                    "mailingAddress": "742 Evergreen Terrace Springfield, JF 58008"
                }
            ],
            "issuanceDate": "2024-05-29T05:07:18.606Z",
            "expirationDate": "2029-05-29T05:07:17.110Z",
            "proof": {
                "type": "Ed25519Signature2018",
                "creator": "did:earthid:testnet:EvU7xs7swFEYqANkFDQDvSP97EzaVmVv3E5K5L3uKMXk;earthid:testnet:fid=0.0.4243508",
                "created": "2024-05-29T05:07:18.606Z",
                "proofPurpose": "assertionMethod",
                "vcVerificationMethod": "did:earthid:testnet:EvU7xs7swFEYqANkFDQDvSP97EzaVmVv3E5K5L3uKMXk;earthid:testnet:fid=0.0.4243508#did-root-key",
                "jws": "eyJjcml0IjpbImI2NCJdLCJiNjQiOmZhbHNlLCJhbGciOiJFZERTQSJ9..YWFhYTBkMDg5YTI4ODAxOTc3MTYxNDk4MWVmYTE2NmMzOTQyNzMyMmMxMzNhOGIzYjRkYzVlMzQ1ODAxNDFhMjZhNGJiYTgxOWY0Y2E2NWQ4Zjg3ZjE3MzYwNGQ1NmQzYmI0NTU3NWJiODg3YzYyOTUyNTM5MzQwNDU4ZDFlMDc="
            },
            "biometrics": {
                "face": null,
                "iris": null,
                "finger": null
            },
            "credentialStatus": ""
        },
        "createdAt": "2024-07-18T09:32:16.448Z",
        "updatedAt": "2024-07-18T09:32:16.448Z",
        "__v": 0
    }
]


    


        const fetchData = async () => {
            try {
                const prefix = 'MarriageCertificate'; // Replace with your desired prefix
                const userNamee = `${prefix}${userName}`;
                console.log('Username', userNamee)
                const cidsResponse = await fetch(`https://publicdb.myearth.id/api/vcnew/company/${userName}/CertificateVc`);
                const cidJson = await cidsResponse.json();
                console.log('CidsResponse', cidJson);

//                 if (cidJson && cidJson.response && cidJson.response.length > 0) {
//                     // const response = await fetch('https://publicdb.myearth.id/api/vc/fetchVCMain', {
//                     //     method: 'POST',
//                     //     headers: {
//                     //         'Accept': 'application/vnd.ipld.car',
//                     //         'Content-Type': 'application/json',
//                     //     },
//                     //     body: JSON.stringify({
//                     //         cids: [
//                     //             cidJson.cids[cidJson.cids.length - 1]
//                     //         ],
//                     //     }),
//                     // });
// setResponseData(cidJson.response)
//                     // if (response.ok) {
//                     //     const json = await response.json();
//                     //     console.log(json);
//                     //     setResponseData(json);
//                     // } else {
//                     //     console.log('Request failed with status code:', response.status);
//                     // }
//                 } else {
//                     console.log('Certificate not generated');
//                     Swal.fire('Certificate Not Generated');
//                 }

setResponseData(responseVC)
            } catch (error) {
                console.log('Error:', error);
            }
            finally {
                setIsLoading(false);
            }
        };

      






    useEffect(() => {
        if (isButtonClicked && responseData) {
            //   setIsLoading(true);
            setIsVerificationLoading([true, true, true, true, true]);
            setVerificationData([]);

            const groomName = responseData[0].vc.credentialSubject[0].spouse1;
    const brideName = responseData[0].vc.credentialSubject[0].spouse2;
    const names = `${groomName} & ${brideName}`;

            setTimeout(() => {
                //setIsLoading(false);
                setIsVerificationLoading([true, true, true, true, true]);
                setVerificationData([
                    moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                    "NA",
                    "EarthID",
                    names
                ]);

                setTimeout(() => {
                    setIsVerificationLoading([false, true, true, true, true]);
                    setVerificationData([
                        moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                        "NA",
                        "EarthID",
                        names
                    ]);

                    setTimeout(() => {
                        setIsVerificationLoading([false, false, true, true, true]);
                        setVerificationData([
                            moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                            "NA",
                            "EarthID",
                            names
                        ]);

                        setTimeout(() => {
                            setIsVerificationLoading([false, false, false, true, true]);
                            setVerificationData([
                                moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                                "NA",
                                "EarthID",
                                names
                            ]);

                            setTimeout(() => {
                                setIsVerificationLoading([false, false, false, false, true]);
                                setVerificationData([
                                    moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                                    "NA",
                                    "EarthID",
                                    names
                                ]);

                                setTimeout(() => {
                                    setIsVerificationLoading([false, false, false, false, false]);
                                    setVerificationData([
                                        moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                                        "NA",
                                        "EarthID",
                                        names
                                    ]);
                                }, 1000);
                            }, 1000);
                        }, 1000);
                    }, 1000);
                }, 1000);
            }, 12000);
        }
    }, [isButtonClicked, responseData]);

    const getYearandMonth = (responseData) => {
        if (responseData && responseData.length > 0) {
            try {
                // Extract dates from credentialSubject[0]
                const startDate = responseData[0].vc.credentialSubject[0].startDate;
                const endDate = responseData[0].vc.credentialSubject[0].endDate;
    
                if (!startDate || !endDate) {
                    console.error("Start date or End date is missing.");
                    return null;
                }
    
                // Convert "25/10/2023" -> moment("25/10/2023", "DD/MM/YYYY")
                const issueDate = moment(startDate, "DD/MM/YYYY");
                const expiryDate = moment(endDate, "DD/MM/YYYY");
    
                if (!issueDate.isValid() || !expiryDate.isValid()) {
                    console.error("Invalid date format in credentialSubject.");
                    return null;
                }
    
                // Extract Year & Month
                const issueYear = issueDate.format("YYYY");
                const issueMonth = issueDate.format("MM");
                const expiryYear = expiryDate.format("YYYY");
                const expiryMonth = expiryDate.format("MM");
    
                console.log(`Issue Year: ${issueYear}, Issue Month: ${issueMonth}`);
                console.log(`Expiry Year: ${expiryYear}, Expiry Month: ${expiryMonth}`);
    
                return {
                    issueYear,
                    issueMonth,
                    expiryYear,
                    expiryMonth,
                };
            } catch (error) {
                console.error("Error processing dates:", error);
                return null;
            }
        }
        return null;
    };
    

    const extractLastPart = (responseData) => {
        if (responseData && responseData.length > 0 && responseData[0].vc.id) {
            const id = responseData[0].vc.id;
            const parts = id.split('-');
            const lastPart = parts[parts.length - 1];
            return lastPart;
        }
        return null;
    }

    const lastPart = extractLastPart(responseData);

    const dateData = getYearandMonth(responseData)
    console.log(dateData)

 

 





    //Verify Api

    const createUserSignature = async (documentMetadata) => {
        try {
            const userDid = await createUserDid();
            const payload = {
                payload: {
                    credentialSubject: {
                        id: userDid,
                        pdfMetaData: documentMetadata,
                    },
                },
            };

            const config = {
                method: 'post',
                url: `${ssiBaseUrl}/api/user/sign?issuerDID=${issuerDID}`,
                headers: {
                    'X-API-KEY': authorizationKey,
                    privateKey,
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(payload),
            };

            const response = await axios.request(config);
            console.log(response.data)
            return response.data.Signature;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };


    // const verifyVC = async () => {
    //     try {

    //         //const signature = await createUserSignature();

    //         const data = {
    //             "credentials": {
    //                 ...responseData[0].vc
    //             }
    //         }
    //         const config = {
    //             method: 'post',
    //             url: ssiBaseUrl + "/api/verifier/verify?zpkValidation=false",
    //             headers: {
    //                 'X-API-KEY': authorizationKey,
    //                 'publicKey': publicKey,
    //                 //'userSignature': signature, // Call the createUserSignature function
    //                 'Content-Type': 'application/json'
    //             },
    //             data: JSON.stringify(data)
    //         };

    //         const response = await axios.request(config);
    //         console.log(JSON.stringify(response.data));

    //         return response.data;
    //     } catch (error) {
    //         console.log(error);
    //         throw error;
    //     }
    // }

    const verifyVC = async () => {
        try {

            //const signature = await createUserSignature();

            const data = {
                "credentials": {
                    ...responseData[0].vc
                }
            }
            const config = {
                method: 'post',
                url: ssiBaseUrl + "/api/verifier/verify?zpkValidation=false",
                headers: {
                    'X-API-KEY': authorizationKey,
                    'publicKey': publicKey,
                    //'userSignature': signature, // Call the createUserSignature function
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            };

            const response = await axios.request(config);
            console.log(JSON.stringify(response.data));

            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }


    const createUserDid = async () => {
        // Implement the logic to create a user DID (if required)
        // For now, returning a placeholder value
        return 'did:earthid:testnet:9WzSyPSBkbUQVktjGxmvKAzbTNJCs1PFqo2fQ6BnuHiE;earthid:testnet:fid=0.0.15057499';
    };

    //Verify Api

    const handlePopupOpen = () => {
        setIsPopupOpen(true);
        setIsButtonClicked(true);
        setIsVerifying(true);


        // Call verifyVC() here
        verifyVC().then((verificationResult) => {
            // Process the verification result and update state variables accordingly
            setIsVerifying(false);
            // Update other state variables based on verification result
            // Example:
            // setIsVerificationLoading([...]);
            // setVerificationData([...]);
        }).catch((error) => {
            // Handle error
            setIsVerifying(false);
            // Display error message or take appropriate action
        });
    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);
    };

    // function toggleView() {
    //     setShowJson(!showJson);
    //   }


    const handleMailClick = () => {
        const subject = encodeURIComponent(shareSubject);
        const body = encodeURIComponent(shareBodyMail);
        const mailtoUrl = `mailto:?subject=${subject}&body=${body}`;

        const a = document.createElement('a');
        a.href = mailtoUrl;
        a.target = '_blank';
        a.rel = 'noopener noreferrer';
        a.click();
    };

    const handleViewCertificate = () => {
        const MySwal = withReactContent(Swal);

        MySwal.fire({
            title: 'Digital Certificate(VC)',
            html: <pre>{JSON.stringify(responseData[0].vc, null, 2)}</pre>,
            confirmButtonText: 'Verify',
            showCancelButton: true,
            cancelButtonText: 'Close',
            customClass: {
                content: 'certificate-swal',
                confirmButton: 'swal-button',
                cancelButton: 'swal-button swal-button--cancel',
            },
            width: '1000px',
            didOpen: () => {
                const verifyButton = MySwal.getConfirmButton();
                verifyButton.addEventListener('click', () => {
                    const loadingSwal = MySwal.mixin({
                        title: 'Loading',
                        text: 'Performing verification...',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showConfirmButton: false,
                        onOpen: () => {
                            loadingSwal.showLoading();
                            verifyVC()
                                .then(() => {
                                    // Display success message upon successful verification
                                    loadingSwal.close();
                                    MySwal.fire({
                                        title: 'Verification Successful',
                                        text: '',
                                        icon: 'success',
                                        showConfirmButton: true,
                                        confirmButtonText: 'OK',
                                        customClass: {
                                            confirmButton: 'swal-button',
                                        },
                                    });
                                })
                                .catch((error) => {
                                    // Handle error case and display error message
                                    loadingSwal.close();
                                    MySwal.fire({
                                        title: 'Verification Error',
                                        text: 'An error occurred during verification.',
                                        icon: 'error',
                                        confirmButtonText: 'OK',
                                        allowOutsideClick: true,
                                        allowEscapeKey: true,
                                        showCancelButton: false,
                                        customClass: {
                                            confirmButton: 'swal-button',
                                        },
                                    });
                                });
                        },
                    });

                    loadingSwal.fire();
                });
            },
        });
    };

    const handleToggleCertificate = () => {
        setShowCertificate(true);
    };

    const handleToggleBadge = () => {
        setShowCertificate(false);
    };

    // Function to handle the PDF download
    const handleDownload = () => {
        const pdfContainer = pdfContainerRef.current;

        // Create a new jsPDF instance with the A4 landscape dimensions
        const pdf = new jsPDF('l', 'pt', [550, 740]);

        // Use html2canvas to render the content of the container as an image
        html2canvas(pdfContainer, { scale: 3, useCORS: true }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            pdf.addImage(imgData, 'JPEG', 0, 0, 742, 550);
            //pdf.save(`training-certificate(${userName}).pdf`);
            pdf.save(`${userName} - MarriageCertificate.pdf`);
            // Open the PDF in a new window
            //   const pdfWindow = window.open('', '_blank');
            //   pdfWindow.document.write('<iframe width="100%" height="100%" src="' + pdf.output('datauristring') + '"></iframe>');
        });
    };

    const handleCopyLink = (link) => {
        navigator.clipboard.writeText(link)
    };
    

    const handleHelpClick = () => {
        Swal.fire({
            title: 'Help',
            html: `
            <div style="text-align: left;">
              <p><strong>How to Use:</strong></p>
              <p>1. To verify the certificate, click on the verify button.</p>
              <p>2. To download the certificate, click on the 'Download' button.</p>
              <p>3. For any assistance, contact support at support@myearth.id</p>
            </div>
          `,
            icon: 'info',
            showCloseButton: true,
            didOpen: () => {
                setTimeout(() => {
                    const closeButton = document.querySelector('.swal2-close');
                    if (closeButton) {
                        closeButton.innerHTML = ""; // Clear default text
                        
                        // ✅ Create a new div to hold the FontAwesome icon
                        const iconElement = document.createElement("div");
                        closeButton.appendChild(iconElement);
                        
                        // ✅ Use React to render FontAwesome inside SweetAlert2 close button
                        ReactDOM.render(<FontAwesomeIcon icon={faCircleXmark} style={{ color: "#7f7f7f", fontSize: "25px"}}/>, iconElement);
                    }
                }, 100);
            },
            showConfirmButton: false,
            customClass: {
                popup: 'swal-custom-popup',
                title: 'swal-custom-title',
                closeButton: 'swal-custom-close-button'
            }
        });
    };
   
    if (!isLoggedIn) {
        return null; // Hide the page until login is successful
    }

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            {responseData && responseData.length > 0 ? (
                <div className="page-container">
                    <nav className="navbar-cert">
                        <div className="logo">
                            <img src={logo}></img>
                        </div>
                        <ul className="nav-menu">
                            <li className="nav-item"><a onClick={handleHelpClick}>Help</a></li>
                        </ul>
                    </nav>
                    <div className="certificate-containere">
                        
                                <div className={showCertificate ? 'certBackk' : 'badgeBackgba'}>
                                <div className='row'>
                                <div className='col-md-8'>
                                    <div >

                                    
                                        {showCertificate ? (
                                            <div ref={pdfContainerRef} className="certificateddem">
                                                <div className="bodboxx">
                                                <div className="cert-number">No. <span className='name'>{responseData[0].vc.credentialSubject[0].docNumber}</span></div>
                                                    <div className="header">
                                                        
                                                        {/* <div className="doc-info">
                                                            <div>DOC # 839498</div>
                                                            <div>Official Records Springfield County Jefferson</div>
                                                            <div>Joe Quimby — Recorder</div>
                                                            <div>08/07/2010 10:50:58 AM</div>
                                                            <div>Requested By: Milhouse Van Houten</div>
                                                            <div>Recorded By: dg</div>
                                                            <div>Recording Fee: $0.00</div>
                                                            <div>Non-Conformity Fee: $</div>
                                                        </div>
                                                        <div className="official-use">FOR OFFICIAL USE ONLY</div> */}
                                                        
                                                    </div>
                                                    <div className="state">State of {responseData[0].vc.credentialSubject[0].issuanceState}</div>
                                                    <div className="title">Marriage Certificate</div>
                                                    
                                                    <div className="info">
                                                        {/* <div className="state-county">
                                                            <div>State of {responseData[0].vc.credentialSubject[0].issuanceState}</div>
                                                            <div>County of {responseData[0].vc.credentialSubject[0].issuancePlace}</div>
                                                        </div> */}
                                                        
                                                        {/* <div className="minister-info">
                                                            <div>Ordained Minister</div>
                                                            <div>1st August</div>
                                                            <div>Springfield Station Springfield</div>
                                                        </div> */}
                                                    </div>
                                                    
                                                    <div className="body">
                                                        <div className='body1'>This is to certify that <span className="name">{responseData[0].vc.credentialSubject[0].issuedBy}</span>, <span className="name">{responseData[0].vc.credentialSubject[0].issuanceAuthority} </span>
                                                           did on <span className="name">{responseData[0].vc.credentialSubject[0].marriageDate} </span>
                                                            at <span className="name">{responseData[0].vc.credentialSubject[0].placeOfMarriage} </span>
                                                            join in lawful wedlock <span className="name">{responseData[0].vc.credentialSubject[0].spouse1} </span>
                                                            of <span className="name">{responseData[0].vc.credentialSubject[0].spouse1Place} </span> State of <span className="name">{responseData[0].vc.credentialSubject[0].spouse1State} </span> Date of Birth <span className="name">{responseData[0].vc.credentialSubject[0].spouse1DOB} </span>
                                                            and  <span className="name">{responseData[0].vc.credentialSubject[0].spouse2} </span>
                                                            of <span className="name">{responseData[0].vc.credentialSubject[0].spouse2Place} </span> State of <span className="name">{responseData[0].vc.credentialSubject[0].spouse2State} </span> Date of Birth <span className="name">{responseData[0].vc.credentialSubject[0].spouse2DOB} </span>
                                                            with their mutual consent in the presence of <span className="name">{responseData[0].vc.credentialSubject[0].witness1} </span>
                                                            and <span className="name">{responseData[0].vc.credentialSubject[0].witness2} </span>who were witnessess.
                                                            </div>
                                                        {/* <div className="name">Homer J. Simpson</div>
                                                        <div className="and">&</div>
                                                        <div className="name">Lisa Marie Simpson</div>
                                                        <div className="address">
                                                            <div>742 Evergreen Terrace</div>
                                                            <div>Springfield, JF 58008</div>
                                                        </div> */}
                                                    </div>
                                                    
                                                    
                                                    
                                                    <div className="qr-and-signature">
                                                        
                                                        <div className='body1'>Couple's mailing address:<br/>
                                                        <span className="name">{responseData[0].vc.credentialSubject[0].mailingAddress}</span>
                                                        
                                                        </div>
                                                        
                                                        <div className="qrcode-container">
                                                            <QRCode className='qrCode' value={qrCodeUrl} />
                                                        </div>
                                                        <div className="minister-signature">
                                                        <div className='body1'>{responseData[0].vc.credentialSubject[0].issuanceAuthority}<br/>
                                                        <span className="name">{responseData[0].vc.credentialSubject[0].issuedBy}</span>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>) : (
                                            <div className="badge-containerr">
                                               
                                            </div>
                                        )}
                                    </div>

                                </div>
                                <div className='rightsect col-md-4'>
                                <div className="footer2t">
                                    <div className='verify'>Certificate Verification</div>
                                    <div className='verify1'>
                                        <FontAwesomeIcon className='fontIcon21' icon={faCheckCircle} />
                                        This certificate is from a verified issuer
                                    </div>
                                    <div className='verify1'>
                                        {/* <div className="check">
                                            <img src={check}></img>
                                        </div> */}
                                        <FontAwesomeIcon className='fontIcon21' icon={faCheckCircle} />
                                        Certificate ID: {lastPart}
                                    </div>
                                    <button className="verify-btnt" onClick={handlePopupOpen}>
                                        Verify
                                    </button>
                                    {/* <button className='verify-btn' onClick={handleViewCertificate}>View Digital Certificate</button> */}
                                    <button className='verify-btnt' onClick={handleDownload}>Download</button>
                                </div>
                                <div className="footer2t">
                                    <div className='sharecontainert'>
                                        
                                        <div className=''>
                                            <div className='verify'>Share Credential</div>
                                            <div className='verify1'>Show this credential on your social network</div>
                                        </div>
                                        <div className='yellowBadget'>
                                            <img src={badgeIcon}></img>

                                        </div>
                                       
                                        
                                    </div>

                                    <div className='row iconns-div'>
                                        <a className='shareIcont' onClick={handleMailClick} style={{ cursor: 'pointer' }}>
                                            <FontAwesomeIcon className='iconns' icon={faEnvelope} />
                                        </a>
                                       
                                       
                                        <WhatsappShareButton
                                            url={certUrl}
                                            title={shareBody}
                                        >
                                            <FontAwesomeIcon className='iconns' icon={faWhatsapp} />
                                        </WhatsappShareButton>
                                        {/* <LinkedinShareButton
                                                url={certUrl}
                                                summary={shareBody}
                                            >
                                                <FontAwesomeIcon className='iconns' icon={faWhatsapp} />
                                            </LinkedinShareButton> */}
                                        {/* LinkedIn Share (Custom URL) */}
                                        {/* <a className='shareIcon' href={linkedInShareUrl} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon className='iconns' icon={faLinkedin} />
    </a> */}
                                    </div>
                                    <button className='verify-btnt' onClick={() => handleCopyLink(qrCodeUrl)}>
                                            <FontAwesomeIcon className='iconns1' icon={faLink} />  Copy Link
                                        </button>
                                    
                                    {/* <a href="https://www.linkedin.com/company/myearthid/" target="_blank"><FontAwesomeIcon icon={faLinkedin}/></a> */}

                                </div>

                            </div>
                            </div>

                            

                        </div>


                    </div>
                    {/* Add the PDF download button */}
                    {/* Add the download button */}

                    <section className='infosecgba'>
                        <div >
                            <div className='row'>
                                <div className='leftsec col-md-8'>
                                    {/* <a href='https://gbaglobal.org/' target='blank'>
                                        <div className='issuerName'>

                                            <FontAwesomeIcon className='issuerfontIcon1' icon={faMedal} />

                                            <div className='gbaLogo1'>
                                               <img src={GbaLogo}></img>
                                                </div>
                                                Government Blockchain Association

                                            <FontAwesomeIcon className='issuerfontIcon' icon={faArrowUpRightFromSquare} />


                                        </div>
                                    </a> */}

                                    <div className='row'>
                                        <div className="check col-md-1">
                                            <img src={check}></img>
                                        </div>
                                        <div className='coursename col-md-11'>Marriage Certificate</div>
                                    </div>
                                    {/* <div className='allBtns row'>
                                        <button className='pdfbtn' title='Download Pdf' onClick={handleDownload}>
                                            <FontAwesomeIcon className='fontIcon' icon={faFilePdf} />
                                            PDF
                                        </button>
                                        <button className='pdfbtn' title='Feature Currently Disabled'>
                                            <FontAwesomeIcon className='fontIcon' icon={faImage} />
                                            BADGE
                                        </button>
                                        <button className='pdfbtn' title='Feature Currently Disabled'>
                                            <FontAwesomeIcon className='fontIcon' icon={faShare} />
                                            EMAIL
                                        </button>
                                        <button className='pdfbtn' title='Feature Currently Disabled'>
                                            <FontAwesomeIcon className='fontIcon' icon={faPen} />
                                            EMBED
                                        </button>
                                        <button className='pdfbtn' title='Feature Currently Disabled'>
                                            <FontAwesomeIcon className='fontIcon' icon={faQuestionCircle} />
                                            HELP
                                        </button>
                                        <button className='pdfbtn' title='Feature Currently Disabled'>

                                            MORE
                                            <FontAwesomeIcon className='fontIcon1' icon={faCaretDown} />
                                        </button>
                                    </div> */}
                                    {/* <div className='studNameDiv' style={{ textTransform: 'capitalize' }}>
                                        <FontAwesomeIcon className='fontIcon' icon={faBuilding} />
                                        {userName}
                                    </div> */}
                                    {/* <a >
                                        <div className='viewCreds'>View All Credentials</div>
                                    </a> */}


                                    {/* <div className='stuID'>ID: EarthID</div> */}


                                    <hr></hr>
                                    <div className='body1'>This is to certify that <span className="name">{responseData[0].vc.credentialSubject[0].issuedBy}</span>, <span className="name">{responseData[0].vc.credentialSubject[0].issuanceAuthority} </span>
               did on <span className="name">{responseData[0].vc.credentialSubject[0].marriageDate} </span>
                at <span className="name">{responseData[0].vc.credentialSubject[0].placeOfMarriage} </span>
                join in lawful wedlock <span className="name">{responseData[0].vc.credentialSubject[0].spouse1} </span>
                of <span className="name">{responseData[0].vc.credentialSubject[0].spouse1Place} </span> State of <span className="name">{responseData[0].vc.credentialSubject[0].spouse1State} </span> Date of Birth <span className="name">{responseData[0].vc.credentialSubject[0].spouse1DOB} </span>
                and  <span className="name">{responseData[0].vc.credentialSubject[0].spouse2} </span>
                of <span className="name">{responseData[0].vc.credentialSubject[0].spouse2Place} </span> State of <span className="name">{responseData[0].vc.credentialSubject[0].spouse2State} </span> Date of Birth <span className="name">{responseData[0].vc.credentialSubject[0].spouse2DOB} </span>
                with their mutual consent in the presence of <span className="name">{responseData[0].vc.credentialSubject[0].witness1} </span>
                and <span className="name">{responseData[0].vc.credentialSubject[0].witness2} </span>who were witnessess.
                </div>
                                    {/* <div className='skillsdiv'>
                                        <p>Skills/Knowledge</p>
                                        <div className='row'>
                                            <div className='allSkills'>
                                                Javascript
                                            </div>
                                            <div className='allSkills'>
                                                React
                                            </div>
                                            <div className='allSkills'>
                                                Node
                                            </div>
                                            <div className='allSkills'>
                                                Hashgraph
                                            </div>

                                        </div>
                                    </div> */}
                                    <hr></hr>

                                    <div className='row'>
                                                                           <div className='issueon col-md-6'>
                                                                               <div>Issued On</div>
                                                                               <div>{moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY, h:mm:ss A')}</div>
                                                                           </div>
                                                                           <div className='expiry col-md-6'>
                                                                               <div>Expires On</div>
                                                                               <div>NA</div>
                                                                           </div>
                                                                       </div>

                                </div>
                                <div className='rightsect col-md-4'>

                                    <div className="footer2t" style={{ alignItems: 'center' }}>
                                        <div className='verify text-center' style={{ marginBottom: '-30px', fontSize: '1.5rem' }}>About the Issuer</div>
                                        <div className="logowhite" style={{ marginBottom: '0px' }}>
                                            <img src={logoWhite}></img>
                                        </div>
                                        <div className='verify1 text-center' style={{ marginBottom: '50px', marginTop:'-50px', fontSize: '1rem' }}>
                                            EarthID is a multi-award-winning decentralized identity platform enabling organizations to 
                                            prevent identity frauds, drive compliance and foster customer trust.</div>
                                        {/* <button className="verify-btn" onClick={() => window.open('https://myearth.id', '_blank', 'noopener')} rel="noopener">
                                            Visit Issuer Website
                                            <FontAwesomeIcon className='issuerfontIcon' icon={faArrowUpRightFromSquare} />
                                        </button> */}
                                        <div className='row iconns-div'>
                                            <a className='shareIcon' href='https://myearth.id/' target="_blank">
                                                <FontAwesomeIcon className='iconns' icon={faGlobe} />
                                            </a>
                                            <a className='shareIcon' href="https://www.linkedin.com/company/cms-cameron-mckenna/" target="_blank">
                                                <FontAwesomeIcon className='iconns' icon={faLinkedinIn} />
                                            </a>
                                            {/* <a className='shareIcon' href="https://www.instagram.com/gbaglobal/" target="_blank">
                                                <FontAwesomeIcon className='iconns' icon={faInstagram} />
                                           </a> */}
                                            {/* <a className='shareIcon' href='https://twitter.com/CMS_law' target="_blank">
                                                
                                                <div className='iconns3'>
                                                    <img src={Xlogo} className='iconns2'></img>
                                                </div>
                                            </a> */}

                                            {/* <a className='shareIcon' href=''>
                                                <FontAwesomeIcon className='iconns' icon={faGlobe} />
                                           </a> */}
                                        </div>
                                        {/* <button className="verify-btn">
                                            View All Credentials
                                        </button> */}
                                    </div>
                                    {/* <div className="footer2">
                                        <div className='verify'>More about the Issuer</div>
                                        <div className='verify1'>Government Blockchain Association</div>
                                        <button className="verify-btn" onClick={() => window.open('https://gbaglobal.org/', '_blank', 'noopener')} rel="noopener">
                                            Visit Issuer Website
                                            <FontAwesomeIcon className='issuerfontIcon' icon={faArrowUpRightFromSquare} />
                                        </button>
                                    </div> */}
                                </div>

                            </div>

                        </div>

                    </section>



                    {isPopupOpen && (
                        <div className="popup-container">
                            {isVerifying ? (
                                <div>
                                    <div className="spinner"></div>
                                    <div className="spinner-text">Verifying Credentials...</div>
                                </div>
                            ) : (
                                <div className="popup">
                                    <div className="popup-header">
                                        <div className="popup-title">Credential Verification Details</div>
                                        <button className="popup-close" onClick={handlePopupClose}>
                                            <FontAwesomeIcon className='fontIcon21cms' icon={faCircleXmark} />
                                        </button>
                                    </div>
                                    <hr></hr>
                                    <div className="popup-body">
                                        {isVerificationLoading[0] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Verifying Issuer...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>

                                                    </div>
                                                    <div className="verification-label">Verified Issuer:</div>

                                                    <div className="verification-result1 row">

                                                        <a href='https://myearth.id/' target='blank'>
                                                            <div className='issuerNamepopcms'>

                                                                {/* <FontAwesomeIcon className='issuerfontIcon1' icon={faMedal} /> */}

                                                                {issuer}

                                                                <FontAwesomeIcon className='issuerfontIconcms' icon={faArrowUpRightFromSquare} />


                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        {/* <div className="success">&#10004;</div> */}
                                                    </div>
                                                    {/* <div className="verification-label">Issuer is Valid:</div> */}

                                                    <div className="verification-result1cms">
                                                        <div>This credential was issued by a trusted and verified source.</div>


                                                    </div>
                                                </div>

                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[1] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Checking Certificate ID...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                    </div>
                                                    <div className="verification-label">Credential Authenticity:</div>

                                                    <div className="verification-result1">
                                                        {/* {verificationData[1] && <div>{verificationData[1]}</div>} */}
                                                        <div>Successfully Verified.</div>

                                                    </div>
                                                </div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        {/* <div className="success">&#10004;</div> */}
                                                    </div>
                                                    {/* <div className="verification-label">Issued by:</div> */}

                                                    <div className="verification-result1cms">
                                                        {/* {verificationData[1] && <div>{verificationData[1]}</div>} */}
                                                        <div>This credential’s authenticity has been independently confirmed.</div>

                                                    </div>
                                                </div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        {/* <div className="success">&#10004;</div> */}

                                                    </div>
                                                    {/* <div className="verification-label">Issued by:</div> */}

                                                    <div className="verification-result1cms">
                                                        {/* {verificationData[1] && <div>{verificationData[1]}</div>} */}
                                                        <div className="footer22">
                                                            <div className='verify1'>
                                                                {/* <FontAwesomeIcon className='fontIcon22' icon={faCheckCircle} /> */}
                                                                Certificate ID: {lastPart}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[2] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Verifying Owner...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                    </div>
                                                    <div className="verification-label">Issued to:</div>

                                                    <div className="verification-result1">
                                                        {/* {verificationData[1] && <div>{verificationData[1]}</div>} */}
                                                        <div>{userName}</div>

                                                    </div>
                                                </div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        {/* <div className="success">&#10004;</div> */}
                                                    </div>
                                                    {/* <div className="verification-label">Issued by:</div> */}

                                                    <div className="verification-result1cms">
                                                        {/* {verificationData[1] && <div>{verificationData[1]}</div>} */}
                                                        <div>The recipient of this credential has been verified.</div>

                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[3] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Checking Certificate Status...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>

                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                        <div></div>
                                                    </div>

                                                    <div className="verification-label">Issued on:</div>

                                                    <div className="verification-result1">
                                                    {responseData[0].vc.issuanceDate}
                                                    </div>


                                                </div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                        <div></div>
                                                    </div>

                                                    <div className="verification-label">Expires on:</div>

                                                    <div className="verification-result1">
                                                    NA
                                                    </div>

                                                </div>


                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[4] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Verifying Credential Data...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success1"><img src={check}></img></div>
                                                    </div>
                                                    <div className="verification-label"></div>

                                                    <div className="verification-result11">
                                                        <div className='verified'>Marriage Certificate is Verified</div>

                                                        <div className='verified1'>This digital credential was securely issued by EarthID and remains valid.</div>


                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}

                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    <footer className="footerr">
                        <div className="footerr-text">
                            © 2025 EarthID. All Rights Reserved.
                        </div>
                    </footer>
                </div>
            ) : (
                Swal.fire({
                    title: "No Certificate Found",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: "Go Back",
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Redirect to the home page
                        window.location.href = "/uploadDoc";
                    }
                })
            )}
        </>

    );
};

export default MarriageCert;
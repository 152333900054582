import React, { useState, useEffect, Component }  from 'react'
import KycFlow from '../../kycQr'
import '../../App.css'
import './applyMarriageCert.css'

import { Document, Page, pdfjs } from 'react-pdf';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Navbar from '../../Navbar';
import Footer from '../Footer/Footer';
import axios from 'axios';
import data from "../../api";
import openSocket from "socket.io-client";
import 'bootstrap/dist/css/bootstrap.min.css';
import studentPhoto from '../../img/studentIcon.png'
import QRCode from 'qrcode.react';
import { renderToString } from 'react-dom/server'; 
import { toPng } from 'html-to-image';
import { JsonToTable } from "react-json-to-table";
import uploadBack from '../../img/auditBanerImg.jpeg'
import logo from '../../img/Logo-EarthID-white.png'
import '../HomePublic/test.css'
import crypto from 'crypto';
import EXIF from 'exif-js';
import mammoth from 'mammoth';
import { extract } from 'png-metadata';
//import fileType from 'file-type'
import create from 'jpeg-js'

import { PDFDocument } from 'pdf-lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCalendar, faCheck, faCircleCheck, faEdit, faEye, faFileCircleCheck, faHome, faList, faQrcode, faQuestionCircle, faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';





const fs = require('fs');
const MySwal = withReactContent(Swal);
// export const isTestnet = true;
// const socketURL =
//     isTestnet === true
//         ? "https://socket-stagev2.myearth.id"
//         : "https://socketv2.myearth.id";
// const socket = openSocket(socketURL, {
//     transports: ["websocket", "polling", "flashsocket"],
// });
// let socketId;
// socket.on("connect", () => {
//     socketId = socket.id;
//     //console.log(`I'm connected with the back-end`);
// });



const {
    listenForUserData
} = data;

const ssiBaseUrl = "https://ssi-test.myearth.id";
const authorizationKey = "01a41742-aa8e-4dd6-8c71-d577ac7d463c";

// const privateKey = "6mf4ZKu2ts0V4Dr0sS+YbXYveZOyDezzTJUqa5Ls29BHN4jBTY5we/WW0CgmuNGb3PBvdqqJPhDF2YCMnF4faw==";
 const publicKey = "geMiBNanS2G0apweNStndbsBkKdc8rjzPtbP4N6EApg=";
 const issuerDid = "did:earthid:testnet:EvU7xs7swFEYqANkFDQDvSP97EzaVmVv3E5K5L3uKMXk;earthid:testnet:fid=0.0.4243508";
const userDID = "did:earthid:testnet:DsvEjMRKhboDb7GGYRL2P3BpV5htGx9QB2HPzSWpw89J;earthid:testnet:fid=0.0.4243508";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ApplyMarriage = () => {



    const [files, setFiles] = useState([]);
    const [docID, setDocID] = useState(null);
    const [vcID, setVcID] = useState(null);
    const [responseData, setResponseData] = useState(null);
    const [responseDataID, setResponseDataID] = useState(null);
    const [responseDataTraining, setResponseDataTraining] = useState(null);
    const [responseDataMember, setResponseDataMember] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [pdfFile, setPdfFile] = useState(null);
    const [numPdfPages, setNumPdfPages] = useState(null);
    const [numPages, setNumPages] = useState([]);
    const [pdfMetaData, setPdfMetaData] = useState([]);
    const [pdfs, setPdfs] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [showMainContent, setShowMainContent] = useState(false); // State variable to track tab selection
    const [showAllDocs, setShowAllDocs] = useState(false);
    const [apiResponse, setApiResponse] = useState(null); // State variable to store API response
    const [vcResponse, setVcResponse] = useState(null); // State variable to store API response
    const [verifiableCredentials, setVerifiableCredentials] = useState([]);
    const [idVerifiableCredentials, setIdVerifiableCredentials] = useState([]);
    const [selectedDropCertificate, setSelectedDropCertificate] = useState('');
    const [showTable, setShowTable] = useState(false);
    const [profileData, setProfileData] = useState(null);
    const [selectedButton, setSelectedButton] = useState("");
    const [loading, setLoading] = useState(false); // Added loading state
    const [ qRCodeUrl, setQRCodeUrl] = useState(null);
    const [ onqRCodeUrl, setonQRCodeUrl] = useState(null);
    const [ idqRCodeUrl, setIDQRCodeUrl] = useState(null);
    const [ trainingqRCodeUrl, setTrainingQRCodeUrl] = useState(null);
    const [ memberqRCodeUrl, setMemberQRCodeUrl] = useState(null);
    const [selectedCertificate, setSelectedCertificate] = useState(null);
    const [showSendRequest, setShowSendRequest] = useState(false);
    const [clickedCertificate, setClickedCertificate] = useState('');

    const [partner1KYCComplete, setPartner1KYCComplete] = useState(false);
    const [partner2KYCComplete, setPartner2KYCComplete] = useState(false);
    const [partner1Data, setPartner1Data] = useState({});
    const [partner2Data, setPartner2Data] = useState({});
    const [showPartner1Data, setShowPartner1Data] = useState(false);
    const [showPartner2Data, setShowPartner2Data] = useState(false);

    const [referenceNo, setReferenceNo] = useState("");

    const history = useHistory();




    useEffect(() => {
        getProfileData();
        if (profileData) {
            fetchDocs(profileData.userName);
          } else {
            console.log("Profile data not found");
          }
    }, []);

    const toggleView = () => {
        setShowTable((prevShowTable) => !prevShowTable);
    };

    const getProfileData = async () => {
        try {

            const profileData = sessionStorage.getItem('profileData');
            const bytes = profileData ? JSON.parse(profileData) : null;


            console.log('Bytes', bytes)

            setProfileData(bytes);

            setSelectedDocument(null);

            if (bytes && bytes.userName) {
                // Fetch documents using the userName from profileData if it exists
               await fetchDocs(bytes.userName);
                await fetchData(bytes.userName)
            }
        } catch (error) {
            console.log('Profile data not found');
        }
    };
  //const qrCodeUrl = ``  
//const qrCodeUrl = JSON.stringify(responseData[0]);
const [showAlert, setShowAlert] = useState(false);

  const handleQRCodeClick = (certificate) => {
    if(certificate === 'IDCertificate'){
        setQRCodeUrl(idqRCodeUrl);
    }else if(certificate === 'ProjectCompletion'){
        setQRCodeUrl(qRCodeUrl);
    }else if(certificate === 'Training'){
        setQRCodeUrl(trainingqRCodeUrl);
    }else if(certificate === 'Membership'){
        setQRCodeUrl(memberqRCodeUrl);
    }

  setClickedCertificate(certificate);
    setShowAlert(true);
  };


    const fetchData = async (userName) => {
        try {
            const prefix = 'DeathCertificate'; // Replace with your desired prefix
            const idPrefix = 'EmploymentCertificate';
            const prefixTraining = 'MarriageCertificate'; // Replace with your desired prefix
            const prefixMember = 'BirthCertificate';
            const idUserNamee = `${idPrefix}${userName}`;
            const userNamee = `${prefix}${userName}`;
            const trainingUserNamee = `${prefixTraining}${userName}`;
            const memeberuserNamee = `${prefixMember}${userName}`;
            console.log('Username', userNamee);
            const newQRCodeUrl = `https://publicdb.myearth.id/api/vcnew/company/${userNamee}/CertificateVc`;
            const newIDQRCodeUrl = `https://publicdb.myearth.id/api/vcnew/company/${idUserNamee}/CertificateVc`;
            const newQRCodeUrlTraining = `https://publicdb.myearth.id/api/vcnew/company/${trainingUserNamee}/CertificateVc`;
            const newQRCodeUrlMember = `https://publicdb.myearth.id/api/vcnew/company/${memeberuserNamee}/CertificateVc`;
            const responseOn = await fetch(`https://publicdb.myearth.id/api/vcnew/company/${userNamee}/CertificateVc`);
            const responseID = await fetch(`https://publicdb.myearth.id/api/vcnew/company/${idUserNamee}/CertificateVc`);
            const responseTraining = await fetch(`https://publicdb.myearth.id/api/vcnew/company/${trainingUserNamee}/CertificateVc`);
            const responseMember = await fetch(`https://publicdb.myearth.id/api/vcnew/company/${memeberuserNamee}/CertificateVc`);

            const cidJsonOn = await responseOn.json();
            console.log('CidsResponse', cidJsonOn);

            const cidJsonID = await responseID.json();
            console.log('CidsResponse', cidJsonID);

            const cidJsonTraining = await responseTraining.json();
            console.log('CidsResponse', cidJsonTraining);

            const cidJsonIDMember = await responseMember.json();
            console.log('CidsResponse', cidJsonIDMember);

            setIDQRCodeUrl(newIDQRCodeUrl)
            setonQRCodeUrl(newQRCodeUrl)
            setTrainingQRCodeUrl(newQRCodeUrlTraining)
            setMemberQRCodeUrl(newQRCodeUrlMember)

            if (cidJsonOn && cidJsonOn.response && cidJsonOn.response.length > 0) {
                 setResponseData(cidJsonOn.response);
                // const response = await fetch('https://publicdb.myearth.id/api/vc/fetchVCMain', {
                //     method: 'POST',
                //     headers: {
                //         'Accept': 'application/vnd.ipld.car',
                //         'Content-Type': 'application/json',
                //     },
                //     body: JSON.stringify({
                //         cids: [
                //             cidJson.cids[cidJson.cids.length - 1]
                //         ],
                //     }),
                // });

                // if (response.ok) {
                //     const json = await response.json();
                //     console.log(json);
                    
                // } else {
                //     console.log('Request failed with status code:', response.status);
                // }
            } else {
                console.log('License not generated');
                //Swal.fire('Certificate Not Generated');
            }
            if (cidJsonID && cidJsonID.response && cidJsonID.response.length > 0) {
                setResponseDataID(cidJsonID.response);
               // const response = await fetch('https://publicdb.myearth.id/api/vc/fetchVCMain', {
               //     method: 'POST',
               //     headers: {
               //         'Accept': 'application/vnd.ipld.car',
               //         'Content-Type': 'application/json',
               //     },
               //     body: JSON.stringify({
               //         cids: [
               //             cidJson.cids[cidJson.cids.length - 1]
               //         ],
               //     }),
               // });

               // if (response.ok) {
               //     const json = await response.json();
               //     console.log(json);
                   
               // } else {
               //     console.log('Request failed with status code:', response.status);
               // }
           } else {
               console.log('License not generated');
               //Swal.fire('Certificate Not Generated');
           }
           if (cidJsonTraining && cidJsonTraining.response && cidJsonTraining.response.length > 0) {
            setResponseDataTraining(cidJsonTraining.response);
           // const response = await fetch('https://publicdb.myearth.id/api/vc/fetchVCMain', {
           //     method: 'POST',
           //     headers: {
           //         'Accept': 'application/vnd.ipld.car',
           //         'Content-Type': 'application/json',
           //     },
           //     body: JSON.stringify({
           //         cids: [
           //             cidJson.cids[cidJson.cids.length - 1]
           //         ],
           //     }),
           // });

           // if (response.ok) {
           //     const json = await response.json();
           //     console.log(json);
               
           // } else {
           //     console.log('Request failed with status code:', response.status);
           // }
       } else {
           console.log('License not generated');
           //Swal.fire('Certificate Not Generated');
       }
       if (cidJsonIDMember && cidJsonIDMember.response && cidJsonIDMember.response.length > 0) {
        setResponseDataMember(cidJsonIDMember.response);
       // const response = await fetch('https://publicdb.myearth.id/api/vc/fetchVCMain', {
       //     method: 'POST',
       //     headers: {
       //         'Accept': 'application/vnd.ipld.car',
       //         'Content-Type': 'application/json',
       //     },
       //     body: JSON.stringify({
       //         cids: [
       //             cidJson.cids[cidJson.cids.length - 1]
       //         ],
       //     }),
       // });

       // if (response.ok) {
       //     const json = await response.json();
       //     console.log(json);
           
       // } else {
       //     console.log('Request failed with status code:', response.status);
       // }
   } else {
       console.log('License not generated');
       //Swal.fire('Certificate Not Generated');
   }
        } catch (error) {
            console.log('Error:', error);
        }
        finally {
            setIsLoading(false);
        }
    };


   

  const closeAlert = () => {
    setShowAlert(false);
  };

    const handleProfileButtonClick = () => {
        try {

            const profileData = sessionStorage.getItem('profileData');
            const bytes = profileData ? JSON.parse(profileData) : null;

            console.log('Bytes', bytes)
            setProfileData(bytes);


        } catch (error) {
            console.log('Profile data not found');
        }
    };


    const fetchDocs = async (userName) => {
        try {
            //setLoading(true); // Set loading state to true
            console.log(userName)
            // Fetch the document CIDs for the given user
            // const fetchDocsResponse = await fetch(`https://publicdb.myearth.id/api/doc/fetchDocCids/${userName}`);
            // const fetchDocsData = await fetchDocsResponse.json();
            // console.log(fetchDocsData);

            // if (!Array.isArray(fetchDocsData.cids)) {
            //     throw new Error('Invalid response data format');
            // }

            // // Prepare the request body with the CIDs
            // const requestBody = {
            //     cids: fetchDocsData.cids,
            // };
            // console.log(requestBody);
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
              };
              
              const fetchDocsResponse =  await fetch(`https://publicdb.myearth.id/api/docnew/company/${userName}`)
              const fetchDocsData = await fetchDocsResponse.json();
              console.log('fetchdocsdata',fetchDocsData)

              if (!Array.isArray(fetchDocsData.response)) {
                throw new Error('Invalid response data format');
            }

            // Prepare the request body with the CIDs
            const requestBody = {
                response: fetchDocsData.response,
            };
            console.log(requestBody);
                // .then(response => response.text())
                // .then(result => console.log(result))
                // .catch(error => console.log('error', error));
            // Fetch the files based on the CIDs
            //const fetchFilesResponse = await fetch('https://publicdb.myearth.id/api/doc/retrieveFiles', {
                // const fetchFilesResponse = await fetch(`https://publicdb.myearth.id/api/docnew/company/${userName}`, {
                // method: 'GET',
                // headers: {
                //     'Content-Type': 'application/json',
                // },
                //body: JSON.stringify(requestBody),
            //});
//console.log('FetchFileResponse', fetchDocsResponse)
            // if (!fetchFilesResponse.ok) {
            //     throw new Error('Failed to fetch files');
            // }

            // const fetchFilesText = await fetchFilesResponse.json();
            // console.log(fetchFilesText.fileNames)

            // Set the array of document URLs to the state variable
            setPdfs(requestBody);

            setLoading(false); // Stop loading
        } catch (error) {
            console.error('Error fetching documents:', error);
            setLoading(false); // Stop loading
            // Display Swal alert instead of throwing an error
            // MySwal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: 'Error fetching degree documents. Please try again.',
            // });
        }
    };
   
    const handleCreateID = async (requestType) => {
        try {
           if (profileData.length === 0) {
               // Display a message asking to add a document first
               Swal.fire({
                   icon: 'warning',
                   title: 'No Profile Info',
                   text: 'Please register first.',
               });
               return;
           }
       
       
           // Show the loading Swal alert
           Swal.fire({
               title: 'Requesting',
               text: 'Please wait while we are sending your request.',
               allowOutsideClick: false,
               allowEscapeKey: false,
               allowEnterKey: false,
               showConfirmButton: false,
               onBeforeOpen: () => {
                   Swal.showLoading();
               },
           });
           //const allMetadata = []; // Array to store all metadata for SSI APIs
       
           
       
                   //await createUserIdSignature(profileData);
                   await createIdVC(profileData, requestType)
                   //allMetadata.push(documentMetadata); // Add metadata to the array
                   Swal.fire({
                                icon: 'success',
                                title: 'Successful',
                                text: 'The request has been submitted successfully.',
                            });
           
        }catch (error){
           console.log('Id is not created')
        }
        }

    // const handleCreateID = async () => {
    //     try {
    //       if (profileData.length === 0) {
    //         // Display a message asking to add a document first
    //         Swal.fire({
    //           icon: 'warning',
    //           title: 'No Profile Info',
    //           text: 'Please register first.',
    //         });
    //         return;
    //       }

    //       // Start the createIdVC() process in parallel
    // const createIdPromise = createIdVC(profileData);

      
    //       // Display the loading Swal alert
    //     //   const swalAlert = Swal.fire({
    //     //     title: 'Requesting Student ID',
    //     //     text: 'Please wait while the request for student ID is being sent.',
    //     //     allowOutsideClick: false,
    //     //     allowEscapeKey: false,
    //     //     allowEnterKey: false,
    //     //     showConfirmButton: false,
    //     //     onOpen: async () => {

    //     //          // Show loading animation
    //     // Swal.showLoading();
    //     //       // Simulate a delay (e.g., 2 seconds) before showing the next stage
    //     //       await new Promise(resolve => setTimeout(resolve, 5000));
      
    //     //       // Update the message for the next stage
    //     //       Swal.update({
    //     //         title: 'Allmost there...',
    //     //         text: 'Hold a little bit.',
               
    //     //       });
    //     //       Swal.showLoading();
    //     //       await new Promise(resolve => setTimeout(resolve, 5000));
      
    //     //       // Update the message for the next stage
    //     //       Swal.update({
    //     //         title: 'Sending Request',
    //     //         text: 'Please wait while we are sending your request.',
               
    //     //       });
      
    //     //       // Show loading animation for the ID creation stage
    //     // Swal.showLoading();
    //           // Simulate another delay (e.g., 3 seconds) before creating the ID
    //           //await new Promise(resolve => setTimeout(resolve, 3000));
      
    //           // Perform the process to create student ID
    //           await createIdPromise
    //   console.log('Created ID VC')
    //           // Hide the Swal alert
    //           //Swal.close();
      
    //           // Show success Swal alert if ID creation is successful
    //     //       Swal.fire({
    //     //         icon: 'success',
    //     //         title: 'Successful',
    //     //         text: 'The request for Student ID has been submitted successfully.',
    //     //     });
    //     //     },
    //     //   });
    //     } catch (error) {
    //       console.log('ID is not created');
      
    //       // Show error Swal alert if an error occurs during ID creation
    //       Swal.fire({
    //         icon: 'error',
    //         title: 'ID Creation Error',
    //         text: 'An error occurred during the student ID creation.',
    //       });
    //     }
    //   };
       

    // const handleCreateID = async () => {
    //     try {
    //         if (profileData.length === 0) {
    //             // Display a message asking to add a document first
    //             Swal.fire({
    //                 icon: 'warning',
    //                 title: 'No Profile Info',
    //                 text: 'Please register first.',
    //             });
    //             return;
    //         }


    //         // Show the loading Swal alert
    //         Swal.fire({
    //             title: 'Requesting Student ID',
    //             text: 'Please wait while the request for student ID is being sent.',
    //             allowOutsideClick: false,
    //             allowEscapeKey: false,
    //             allowEnterKey: false,
    //             showConfirmButton: false,
    //             onBeforeOpen: () => {
    //                 Swal.showLoading();
    //             },
    //         });
    //         //const allMetadata = []; // Array to store all metadata for SSI APIs



    //         //await createUserIdSignature(profileData);
    //         await createIdVC(profileData)
    //         //allMetadata.push(documentMetadata); // Add metadata to the array


    //     } catch (error) {
    //         console.log('Id is not created')
    //     }
    // }
    const verifySDD = async (credential, verifyParams) => {
        try {
      
            //const signature = await createUserSignature();
      
            const data = { "credentials": credential, "verifyParams": verifyParams }
            console.log(data)
            const config = {
                method: 'post',
                url: ssiBaseUrl + "/api/verifier/verify?zpkValidation=false",
                headers: {
                    'X-API-KEY': authorizationKey,
                    'publicKey': profileData.userDid,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            };
      
            const response = await axios.request(config);
            console.log(JSON.stringify(response.data));
      
            return response.data;
        } catch (error) {
            console.log(error);
            Swal.fire({
              title: "Verification Failed",
              text: "The verification failed. Please try again.",
              icon: "error",
              willClose: () => {
                window.location.reload();
            }
          });
            throw error;
        }
      }
      
      
      const verifyIDV = async (credential) => {
        try {
      
            //const signature = await createUserSignature();
      
            const data = { "credentials": credential }
            console.log(data)
            const config = {
                method: 'post',
                url: ssiBaseUrl + "/api/verifier/verify?zpkValidation=false",
                headers: {
                    'X-API-KEY': authorizationKey,
                    'publicKey': profileData.userDid,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            };
      
            const response = await axios.request(config);
            console.log(JSON.stringify(response.data));
      
            return response.data;
        } catch (error) {
            console.log(error);
            Swal.fire({
              title: "Verification Failed",
              text: "The verification failed. Please try again.",
              icon: "error",
              willClose: () => {
                window.location.reload();
            }
          });
            throw error;
        }
      }

    const getWalletDoc = (partner) => {
        try {
            console.log('this is the partner:', partner)
            listenForUserData(async (err, res) => {
                let bytes = res.userDetails || res.newreq;
                //                 sessionStorage.setItem('profileData', JSON.stringify(bytes));
                // console.log('Got Session Data')
                //console.log("Upload bytes", bytes.base64);
                if(bytes.certificate){
                  console.log("For ZKP Check, no document upload needed!")
                }else if(bytes.documentVc, bytes.selectedFields){
                  const formattedFields = bytes.selectedFields.map(field => `${field.name}=${field.value}`);
    
    console.log(formattedFields);
                    if(bytes){
                        // Show loading swal
            const loadingSwal = Swal.fire({
              title: 'Loading',
              text: 'Verifying Details...',
              allowOutsideClick: false,
              showConfirmButton: false, 
              onBeforeOpen: () => {
                  Swal.showLoading();
              }
          });
    
                      console.log("For SDD Check, no document upload needed!")
        //Add a function for verifySDD and use swal alert for the response
       // const verifyRes = await verifySDD(bytes.documentVc, formattedFields, bytes.publicKey)
       // console.log("VerifySDD", verifyRes)
    
        loadingSwal.close();
      
            const parsedData = Object.fromEntries(
                formattedFields.map(entry => entry.split('='))
            );

            console.log("Parsed Data:", parsedData);

             // Additional Data from `bytes`
             const additionalData = {
                earthId: bytes.earthId || "",
                publicKey: bytes.publicKey || "",
                email: bytes.userEmail || "",
                mobileNumber: bytes.userMobileNo || "",
            };

            if (partner === "Partner1") {
                setPartner1KYCComplete(true);
                setPartner1Data((prevData) => {
                    const updatedData = { ...prevData, ...parsedData, ...additionalData };
                    console.log("✅ Updated Partner1 Data:", updatedData);
                    return updatedData;
                });
            } else if (partner === "Partner2") {
                setPartner2KYCComplete(true);
                setPartner2Data((prevData) => {
                    const updatedData = { ...prevData, ...parsedData, ...additionalData };
                    console.log("✅ Updated Partner2 Data:", updatedData);
                    return updatedData;
                });
            }
          
    
          // Success alert
          Swal.fire({
              title: "Verification Successful",
              text: "The partner data was received successfully.",
              icon: "success",
              
          });
       
                    }else{
                     
    
                        Swal.fire({
                            title: "Verification Failed",
                            text: "The verification failed. Please try again.",
                            icon: "error",
                            willClose: () => {
                              window.location.reload();
                          }
                        });
                    }
                }else if(bytes.idvVc){
                  
    
                    if(bytes){
                        // Show loading swal
            const loadingSwal = Swal.fire({
              title: 'Loading',
              text: 'Verifying...',
              allowOutsideClick: false,
              showConfirmButton: false, 
              onBeforeOpen: () => {
                  Swal.showLoading();
              }
          });
    
                      console.log("For IDV Proof Check, no document upload needed!")
        //Add a function for verifySDD and use swal alert for the response
        const verifyRes = await verifyIDV(bytes.idvVc[0], bytes.publicKey)
        console.log("VerifyIDV", verifyRes)
    
        loadingSwal.close();
        if (verifyRes.success) {
          
    
          // Success alert
          Swal.fire({
              title: "Verification Successful",
              text: "The verification was successful.",
              icon: "success",
              willClose: () => {
                window.location.reload();
            }
          });
        } else {
         
    
          // Failed alert
          Swal.fire({
              title: "Verification Failed",
              text: "The verification failed. Please try again.",
              icon: "error",
              willClose: () => {
                window.location.reload();
            }
          });
        }
                    }else{
                     
    
                        Swal.fire({
                            title: "Verification Failed",
                            text: "The verification failed. Please try again.",
                            icon: "error",
                            willClose: () => {
                              window.location.reload();
                          }
                        });
                    }
                }else{
                  const userName = bytes.userName
                  const userDID = bytes.userDid
                  const base64Array = bytes.base64;
              const pdfFileNameArray = bytes.docName;
      const docTypeArray = bytes.type;
                  for (let j = 0; j < base64Array.length; j++) {
                    const pdfFileName = pdfFileNameArray[j];
                    let base64 = base64Array[j];
          const docType = docTypeArray[j];
                    // Process the individual file
                    const options = {
                      fileName: pdfFileName,
                  };
                  const prefix = "data:image/png;base64,";
      
      let modifiedBase64 = base64; // Use a different variable
      
      if (base64.startsWith(prefix)) {
        modifiedBase64 = base64.slice(prefix.length); // Remove the prefix
      }
      
      // Convert modifiedBase64 to Uint8Array
      const data = atob(modifiedBase64);
                  //console.log('data', data)
                  const uint8Array = new Uint8Array(data.length);
                  for (let i = 0; i < data.length; ++i) {
                      uint8Array[i] = data.charCodeAt(i);
                  }
      
                  if(docType == 'pdf'){
      
      
                    const blob = new Blob([uint8Array], { type: 'application/pdf' });
                  const url = URL.createObjectURL(blob);
      
      
                  console.log('pdfUrl', url)
                  // Load the PDF document from the Uint8Array
                  const pdfDoc = await PDFDocument.load(uint8Array, { ignoreEncryption: true });
                  //console.log(pdfDoc)
      // Calculate the SHA-256 hash of the PDF content
      const sha256Hash = calculateSHA256(uint8Array);
      console.log('Mobile doc Hash', sha256Hash)
                  console.log('URL converted to PDF successfully!');
                  // Extract metadata from the PDF
                  const metadata = {
                      producer: pdfDoc.getProducer(),
                      createdDate: pdfDoc.getCreationDate(),
                      modifiedDate: pdfDoc.getModificationDate(),
                  };
                  console.log('Metadata:', metadata);
      
                  const uniqueId = generateUniqueId();
                  // const formattedDateTime = formatDateTime(metadata.info.ModDate);
      
                  const documentMetadata = {
                      metadata,
                      uniqueId,
                      // formattedDateTime,
                  };
                  // Save the PDF file path
                  setPdfFile({
                      url: url,
                      metadata: documentMetadata,
                      fileName: pdfFileName,
                  });
      
                  const response = await fetch(url);
                  const blobData = await response.blob();
                  console.log(blobData, response)
                  // Create a new File object
                  const file = new File([blobData], pdfFileName, { type: blobData.type });
      
                  console.log('File', file)
                  Swal.fire({
                      title: 'Uploading Documents...',
                      text: 'Please wait while the documents are being uploaded.',
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                      allowEnterKey: false,
                      showConfirmButton: false,
                      onBeforeOpen: () => {
                          Swal.showLoading();
                      },
                  });
                  const metadataString = JSON.stringify(documentMetadata);
                  const storeDocResponse =  await saveMetadataToDatabase( metadataString, file, sha256Hash);
                  console.log('storeDoc', storeDocResponse)
                  //saveMetadataToLocal(documentMetadata, file);
                  //await createUserSignature(documentMetadata);
                  Swal.showLoading();
                  // Simulate a delay (e.g., 2 seconds) before showing the next stage
                  await new Promise(resolve => setTimeout(resolve, 3000));
          
                  // Update the message for the next stage
                  Swal.update({
                    title: 'Generating digital proofs...',
                    text: 'Creating a digital trail for the KYC process.',
                   
                  });
                  Swal.showLoading();
                  // Simulate a delay (e.g., 2 seconds) before showing the next stage
                  await new Promise(resolve => setTimeout(resolve, 3000));
          
                  // Update the message for the next stage
                  Swal.update({
                    title: 'Almost there...',
                    text: 'Hold a little bit.',
                   
                  });
                  Swal.showLoading();
                  const theVcResponse =  await createVC( documentMetadata)
                 console.log('theVcResponse',theVcResponse)
                  // Simulate a delay (e.g., 2 seconds) before showing the next stage
                  //await new Promise(resolve => setTimeout(resolve, 3000));
                const storeVcResponse = await storeVC( theVcResponse);
                  // Update the message for the next stage
                  console.log('storeVC', storeVcResponse)
      
                  
      
                  setSelectedDocument(null);
                  
                 //await handleCreateID()
      
                 handleProfileButtonClick()
                                         // Fetch documents using the userName from profileData if it exists
                                       await  fetchDocs(userName);
                                       if(storeDocResponse && storeVcResponse){
                                         await associateVcWithDocument(storeDocResponse, storeVcResponse)
                                     }else{
                                         console.log('No ID found')
                                     }
                                       Swal.fire({
                                         icon: 'success',
                                         title: 'Successful',
                                         text: 'KYC documents have been submitted successfully. You would recieve an acknowledgment in your wallet.',
                                         willClose: () => {
                                          window.location.reload();
                                      }
                                     });
      
             
                handleProfileButtonClick()
                  
         
      
                  }
      
                  else if( docType == "jpg"){
                    const blob = new Blob([uint8Array], { type: 'image/jpeg' });
                          const url = URL.createObjectURL(blob);
      
                          // Load the JPG image
                          const image = new Image();
                          image.src = url;
      
                          // You can access image width and height like this:
                          const width = image.width;
                          const height = image.height;
      
                          // Extract metadata from the JPG (you can use exif-parser or other libraries)
                          const metadata = extractJpgMetadata(uint8Array);
                          console.log('JPG Metadata:', metadata);
        // Calculate the SHA-256 hash of the PDF content
        const sha256Hash = calculateSHA256(uint8Array);
        console.log('Mobile doc Hash', sha256Hash)
                    console.log('URL converted to PDF successfully!');
                    // Extract metadata from the PDF
                    
                    
                    const uniqueId = generateUniqueId();
                    // const formattedDateTime = formatDateTime(metadata.info.ModDate);
        
                    const documentMetadata = {
                        metadata,
                        uniqueId,
                        // formattedDateTime,
                    };
                    // Save the PDF file path
                    setPdfFile({
                        url: url,
                        metadata: documentMetadata,
                        fileName: pdfFileName,
                    });
        
                    const response = await fetch(url);
                    const blobData = await response.blob();
                    console.log(blobData, response)
                    // Create a new File object
                    const file = new File([blobData], pdfFileName, { type: blobData.type });
        
                    console.log('File', file)
                    Swal.fire({
                        title: 'Uploading Documents...',
                        text: 'Please wait while the documents are being uploaded.',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: false,
                        onBeforeOpen: () => {
                            Swal.showLoading();
                        },
                    });
                    const metadataString = JSON.stringify(documentMetadata);
                    const storeDocResponse =  await saveMetadataToDatabase( metadataString, file, sha256Hash);
                    console.log('storeDoc', storeDocResponse)
                    //saveMetadataToLocal(documentMetadata, file);
                    //await createUserSignature(documentMetadata);
                    Swal.showLoading();
                    // Simulate a delay (e.g., 2 seconds) before showing the next stage
                    await new Promise(resolve => setTimeout(resolve, 3000));
            
                    // Update the message for the next stage
                    Swal.update({
                      title: 'Generating digital proofs...',
                      text: 'Creating a digital trail for the KYC process.',
                     
                    });
                    Swal.showLoading();
                    // Simulate a delay (e.g., 2 seconds) before showing the next stage
                    await new Promise(resolve => setTimeout(resolve, 3000));
            
                    // Update the message for the next stage
                    Swal.update({
                      title: 'Almost there...',
                      text: 'Hold a little bit.',
                     
                    });
                    Swal.showLoading();
                    const theVcResponse =  await createVC( documentMetadata)
                   console.log('theVcResponse',theVcResponse)
                    // Simulate a delay (e.g., 2 seconds) before showing the next stage
                    //await new Promise(resolve => setTimeout(resolve, 3000));
                  const storeVcResponse = await storeVC( theVcResponse);
                    // Update the message for the next stage
                    console.log('storeVC', storeVcResponse)
        
                    
        
                    setSelectedDocument(null);
                    
                   //await handleCreateID()
        
                   handleProfileButtonClick()
                                           // Fetch documents using the userName from profileData if it exists
                                         await  fetchDocs(userName);
                                         if(storeDocResponse && storeVcResponse){
                                           await associateVcWithDocument(storeDocResponse, storeVcResponse)
                                       }else{
                                           console.log('No ID found')
                                       }
                                         Swal.fire({
                                           icon: 'success',
                                           title: 'Successful',
                                           text: 'KYC documents have been submitted successfully. You would recieve an acknowledgment in your wallet.',
                                           willClose: () => {
                                            window.location.reload();
                                        }
                                       });
        
               
                  handleProfileButtonClick()
                    
           
                  }
      
                  else if( docType == "png"){
                    const blob = new Blob([uint8Array], { type: 'image/png' });
                          const url = URL.createObjectURL(blob);
      
                          // Load the JPG image
                          const image = new Image();
                          image.src = url;
      
                          // You can access image width and height like this:
                          const width = image.width;
                          const height = image.height;
      
                          // Extract metadata from the JPG (you can use exif-parser or other libraries)
                          const metadata = extractPngMetadata(uint8Array);
                          console.log('JPG Metadata:', metadata);
        // Calculate the SHA-256 hash of the PDF content
        const sha256Hash = calculateSHA256(uint8Array);
        console.log('Mobile doc Hash', sha256Hash)
                    console.log('URL converted to PDF successfully!');
                    // Extract metadata from the PDF
                    
                    
                    const uniqueId = generateUniqueId();
                    // const formattedDateTime = formatDateTime(metadata.info.ModDate);
        
                    const documentMetadata = {
                        metadata,
                        uniqueId,
                        // formattedDateTime,
                    };
                    // Save the PDF file path
                    setPdfFile({
                        url: url,
                        metadata: documentMetadata,
                        fileName: pdfFileName,
                    });
        
                    const response = await fetch(url);
                    const blobData = await response.blob();
                    console.log(blobData, response)
                    // Create a new File object
                    const file = new File([blobData], pdfFileName, { type: blobData.type });
        
                    console.log('File', file)
                    Swal.fire({
                        title: 'Uploading Documents...',
                        text: 'Please wait while the documents are being uploaded.',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: false,
                        onBeforeOpen: () => {
                            Swal.showLoading();
                        },
                    });
                    const metadataString = JSON.stringify(documentMetadata);
                    const storeDocResponse =  await saveMetadataToDatabase( metadataString, file, sha256Hash);
                    console.log('storeDoc', storeDocResponse)
                    //saveMetadataToLocal(documentMetadata, file);
                    //await createUserSignature(documentMetadata);
                    Swal.showLoading();
                    // Simulate a delay (e.g., 2 seconds) before showing the next stage
                    await new Promise(resolve => setTimeout(resolve, 3000));
            
                    // Update the message for the next stage
                    Swal.update({
                      title: 'Generating digital proofs...',
                      text: 'Creating a digital trail for the KYC process.',
                     
                    });
                    Swal.showLoading();
                    // Simulate a delay (e.g., 2 seconds) before showing the next stage
                    await new Promise(resolve => setTimeout(resolve, 3000));
            
                    // Update the message for the next stage
                    Swal.update({
                      title: 'Almost there...',
                      text: 'Hold a little bit.',
                     
                    });
                    Swal.showLoading();
                    const theVcResponse =  await createVC( documentMetadata)
                   console.log('theVcResponse',theVcResponse)
                    // Simulate a delay (e.g., 2 seconds) before showing the next stage
                    //await new Promise(resolve => setTimeout(resolve, 3000));
                  const storeVcResponse = await storeVC( theVcResponse);
                    // Update the message for the next stage
                    console.log('storeVC', storeVcResponse)
        
                    
        
                    setSelectedDocument(null);
                    
                   //await handleCreateID()
        
                   handleProfileButtonClick()
                                           // Fetch documents using the userName from profileData if it exists
                                         await  fetchDocs(userName);
                                         if(storeDocResponse && storeVcResponse){
                                           await associateVcWithDocument(storeDocResponse, storeVcResponse)
                                       }else{
                                           console.log('No ID found')
                                       }
                                         Swal.fire({
                                           icon: 'success',
                                           title: 'Successful',
                                           text: 'KYC documents have been submitted successfully. You would recieve an acknowledgment in your wallet.',
                                           willClose: () => {
                                            window.location.reload();
                                        }
                                       });
        
               
                  handleProfileButtonClick()
                    
           
                  }else {
                    // Unsupported document type
                    Swal.fire({
                        icon: 'error',
                        title: 'Unsupported Document Type',
                        text: 'The document type is not supported.',
                        willClose: () => {
                          window.location.reload();
                      }
                    });
                }
                  //console.log('uintArr', uint8Array)
                  
                  }
                 
                }
                 // Convert base64 to PDF
               // const pdfFileName = bytes.docName; // Provide a file name for the PDF
                
              // Show loading animation for the ID creation stage
        
            });
        } catch (error) {
            console.log('Document not found');
        }
    };
    

  

// Function to extract metadata from a JPG file (using exif-parser)
const extractJpgMetadata = (jpgData) => {
    try {
      //const rawImageData = create(jpgData);
      //console.log('RawImageData', rawImageData)
      return {
        producer: 'JPG Producer', // Customize the producer field as needed
        createdDate: new Date().getTime(), // Replace with the actual created date
        modifiedDate: new Date().getTime(), // Replace with the actual modified date
      };
    } catch (error) {
      console.error('Error extracting metadata from JPEG:', error);
      return {};
    }
  };
  
  const extractPngMetadata = (pngUint8Array) => {
    try {
      const metadata = extract(pngUint8Array);
      console.log('png metadata', metadata)
      return {
        producer: 'PNG Producer', // Customize the producer field as needed
        // Extract other PNG metadata fields as needed
      };
    } catch (error) {
      console.error('Error extracting metadata from PNG:', error);
      return {};
    }
  };



    const onDocumentLoadSuccess = ({ numPdfPages }) => {
        setNumPdfPages(numPdfPages);
    };


    const handleKYCFlowComplete = (partner) => {
        // Call the getWalletDoc function


        getWalletDoc(partner);

    };


    const handleViewCertificate = () => {
        // Perform any necessary actions before redirecting

        // Redirect to the desired component
        window.open(`/certificate/project-completion/${profileData.userName}`, '_blank');
    };

    const handleViewIDCertificate = () => {
        // Perform any necessary actions before redirecting

        // Redirect to the desired component
        window.open(`/certificate/employment/${profileData.userName}`, '_blank');
    };

    const handleViewTrainingCertificate = () => {
        // Perform any necessary actions before redirecting

        // Redirect to the desired component
        window.open(`/certificate/training/${profileData.userName}`, '_blank');
    };

    const handleViewMemberCertificate = () => {
        // Perform any necessary actions before redirecting

        // Redirect to the desired component
        window.open(`/certificate/professional-membership/${profileData.userName}`, '_blank');
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles(selectedFiles);
    };

    const handleFileUpload = async () => {
        if (files.length === 0) {
            // Display a message asking to add a document first
            Swal.fire({
                icon: 'warning',
                title: 'Add Document',
                text: 'Please add a document first.',
            });
            return;
        }


        // Show the loading Swal alert
        Swal.fire({
            title: 'Uploading Documents...',
            text: 'Please wait while the documents are being uploaded.',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
        //const allMetadata = []; // Array to store all metadata for SSI APIs

        for (const file of files) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const arrayBuffer = reader.result;
                const pdf = await pdfjs.getDocument(arrayBuffer).promise;

                const numPages = pdf.numPages;
                setNumPages((prevNumPages) => [...prevNumPages, numPages]);

                const metadata = await pdf.getMetadata();
                const uniqueId = generateUniqueId();
                //const formattedDateTime = formatDateTime(metadata.info.ModDate);

                const documentMetadata = {
                    ...metadata.info,
                    uniqueId,
                    //formattedDateTime,
                };

                const metadataString = JSON.stringify(documentMetadata);
            const storeDocResponse =  await saveMetadataToDatabase(metadataString, file);
console.log('storeDoc', storeDocResponse)
               await saveMetadataToLocal(metadataString, file);
                //await createUserSignature(documentMetadata);

                Swal.showLoading();
                // Simulate a delay (e.g., 2 seconds) before showing the next stage
                //await new Promise(resolve => setTimeout(resolve, 5000));
        
                // Update the message for the next stage
                Swal.update({
                  title: 'Generating Verifiable Credential...',
                  text: 'Creating a VC of document metadata.',
                 
                });
                Swal.showLoading();
               const theVcResponse =  await createVC(documentMetadata)
               console.log('theVcResponse',theVcResponse)
                // Simulate a delay (e.g., 2 seconds) before showing the next stage
                //await new Promise(resolve => setTimeout(resolve, 3000));
              const storeVcResponse = await storeVC(profileData.userDid, theVcResponse);
                // Update the message for the next stage
                console.log('storeVC', storeVcResponse)
                Swal.update({
                  title: 'Almost there...',
                  text: 'Hold a little bit.',
                 
                });
                Swal.showLoading();
                
                
                setSelectedDocument(null);
               //await handleCreateID()
 handleProfileButtonClick()
                    // Fetch documents using the userName from profileData if it exists
                  await  fetchDocs(profileData.userName);
                  if(storeDocResponse && storeVcResponse){
                    await associateVcWithDocument(storeDocResponse, storeVcResponse)
                }else{
                    console.log('No ID found')
                }
                //   Swal.fire({
                //     icon: 'success',
                //     title: 'Successful',
                //     text: 'On-boarding documents and its verifiable credentials have been submitted successfully.',
                // });
                //allMetadata.push(documentMetadata); // Add metadata to the array
            };
            reader.readAsArrayBuffer(file);
        }

        // Set showMainContent to true after file upload

        // Display success message after file upload
        //   Swal.fire({
        //     icon: 'success',
        //     title: 'Successful',
        //     text: 'The document has been submitted successfully.',
        //   });

        // Call the SSI APIs using allMetadata

    };


    const generateUniqueId = () => {
        return Math.random().toString(36).substr(2, 9);
    };
    // SSI Apis
    // const createUserSignature = async (documentMetadata) => {
    //     try {
    //         const userDid = await createUserDid();
    //         const payload = {
    //             payload: {
    //                 credentialSubject: {
    //                     id: userDid,
    //                     pdfMetaData: documentMetadata,
    //                 },
    //             },
    //         };

    //         const config = {
    //             method: 'post',
    //             url: `${ssiBaseUrl}/api/user/sign?issuerDID=${issuerDID}`,
    //             headers: {
    //                 'X-API-KEY': authorizationKey,
    //                 privateKey,
    //                 'Content-Type': 'application/json',
    //             },
    //             data: JSON.stringify(payload),
    //         };

    //         const response = await axios.request(config);
    //         console.log(response.data)
    //         return response.data.Signature;
    //     } catch (error) {
    //         console.log(error);
    //         throw error;
    //     }
    // };

    const createVC = async (bytes, documentMetadata, requestType) => {
        try {
            //const signature = await createUserSignature(documentMetadata);
            const data = {
                schemaName: 'SmartCityDocVC:1',
                isEncrypted: false,
                dependantVerifiableCredential: [],
                credentialSubject: {
                    requestType: requestType,
                    userName: bytes.userName,
                    earthId: bytes.earthId,
                    userMobileNo: bytes.userMobileNo,
                    userEmail: bytes.userEmail,
                    pdfMetaData: documentMetadata
                },
            };

            const config = {
                method: 'post',
                url: `${ssiBaseUrl}/api/issuer/verifiableCredential?isCryptograph=false&downloadCryptograph=false`,
                headers: {
                    'X-API-KEY': authorizationKey,
                    did: bytes.userDid,
                    publicKey: bytes.publicKey,
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(data),
            };

            const response = await axios.request(config);
            console.log('VC response', response.data.data.verifiableCredential)
            const verifiableCredential = response.data.data.verifiableCredential;


            // Store the VC using the storeVC API
            
            setVcResponse(verifiableCredential)
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Successful',
            //     text: 'On-boarding documents and its verifiable credentials have been submitted successfully.',
            // });

            // Emit the VC data to the socket connection
            //socket.emit('vcData', verifiableCredential);
            setVerifiableCredentials((prevCredentials) => [...prevCredentials, verifiableCredential]);

            setShowAllDocs(true)
            return verifiableCredential;

        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const createReqCertVC = async (partner1, partner2) => {
        try {
            //const signature = await createUserSignature(documentMetadata);
            const data = {
                schemaName: 'MarriageLicenseReqVC:3',
                isEncrypted: false,
                dependantVerifiableCredential: [],
                credentialSubject: {
                    "partner1": partner1,
                    "partner2": partner2
                },
            };

            const config = {
                method: 'post',
                url: `${ssiBaseUrl}/api/issuer/verifiableCredential?isCryptograph=false&downloadCryptograph=false`,
                headers: {
                    'X-API-KEY': authorizationKey,
                    did: userDID,
                    publicKey: publicKey,
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(data),
            };

            const response = await axios.request(config);
            console.log('VC response', response.data.data.verifiableCredential)
            const verifiableCredential = response.data.data.verifiableCredential;


            // Store the VC using the storeVC API
            const storeVcResponse = await storeVC( verifiableCredential);
            setVcResponse(verifiableCredential)
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Successful',
            //     text: 'On-boarding documents and its verifiable credentials have been submitted successfully.',
            // });

            // Emit the VC data to the socket connection
            //socket.emit('vcData', verifiableCredential);
            // setVerifiableCredentials((prevCredentials) => [...prevCredentials, verifiableCredential]);

            // setShowAllDocs(true)
            return verifiableCredential;

        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    // const createUserIdSignature = async (profileData) => {
    //     try {
    //         const userDid = await createUserDid();
    //         const payload = {
    //             payload: {
    //                 credentialSubject: {
    //                     id: userDid,
    //                     userName: profileData.userName,
    //                     earthId: profileData.earthId,
    //                     userMobileNo: profileData.userMobileNo,
    //                     userEmail: profileData.userEmail
    //                 },
    //             },
    //         };

    //         const config = {
    //             method: 'post',
    //             url: `${ssiBaseUrl}/api/user/sign?issuerDID=${issuerDID}`,
    //             headers: {
    //                 'X-API-KEY': authorizationKey,
    //                 privateKey,
    //                 'Content-Type': 'application/json',
    //             },
    //             data: JSON.stringify(payload),
    //         };

    //         const response = await axios.request(config);
    //         console.log(response.data)
    //         return response.data.Signature;
    //     } catch (error) {
    //         console.log(error);
    //         throw error;
    //     }
    // };


    
    const createIdVC = async (profileData, requestType) => {
        try {
            //const signature = await createUserIdSignature(profileData);
            const data = {
                schemaName: 'ProfessionalCredVC:1',
                isEncrypted: false,
                dependantVerifiableCredential: [],
                credentialSubject: {
                    requestType: requestType,
                    userName: profileData.userName,
                    earthId: profileData.earthId,
                    userMobileNo: profileData.userMobileNo,
                    userEmail: profileData.userEmail
                },
            };

            const config = {
                method: 'post',
                url: `${ssiBaseUrl}/api/issuer/verifiableCredential?isCryptograph=false&downloadCryptograph=false`,
                headers: {
                    'X-API-KEY': authorizationKey,
                    did: profileData.userDid,
                    publicKey: profileData.publicKey,
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(data),
            };

            const response = await axios.request(config);
            console.log('VC response', response.data.data.verifiableCredential)
            const verifiableCredential = response.data.data.verifiableCredential;


            // Store the VC using the storeVC API
            await storeVC(profileData.userDid, verifiableCredential);
            setVcResponse(verifiableCredential)
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Successful',
            //     text: 'The request for Student ID has been submitted successfully.',
            // });

            // Emit the VC data to the socket connection
            //socket.emit('vcData', verifiableCredential);
            setIdVerifiableCredentials((prevCredentials) => [...prevCredentials, verifiableCredential]);

            setShowAllDocs(true)
            return response.data;

        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const createUserDid = async () => {
        // Implement the logic to create a user DID (if required)
        // For now, returning a placeholder value
        return 'did:earthid:testnet:9WzSyPSBkbUQVktjGxmvKAzbTNJCs1PFqo2fQ6BnuHiE;earthid:testnet:fid=0.0.15057499';
    };


    function calculateSHA256(buffer) {
        const hash = crypto.createHash('sha256');
        console.log('Hash', hash)
        hash.update(buffer);
        return hash.digest('hex');
      }
    //SSI Apis

    const saveMetadataToLocal = async (metadata, file) => {
        const storedMetadata = localStorage.getItem('pdfMetaData');
        let allMetadata = storedMetadata ? JSON.parse(storedMetadata) : [];
        allMetadata.push(metadata);
        localStorage.setItem('pdfMetaData', JSON.stringify(allMetadata));
        console.log(allMetadata);

        setPdfMetaData((prevPdfMetaData) => [...prevPdfMetaData, metadata]);
    };

    const saveMetadataToDatabase = async (userName, userDID, metadata, file, sha256Hash) => {
        const formData = new FormData();

        formData.append('doc', file);
        formData.append(
            'company',
            userName
        );

        formData.append(
            'userDID',
            userDID
        );
        formData.append(
            'metaData',
            metadata
        );
        formData.append(
            'docHash',
            sha256Hash
        )

        try {
            //const response = await axios.post('https://publicdb.myearth.id/api/doc/storeApi', formData);
            const response = await axios.post('https://publicdb.myearth.id/api/docnew/store', formData);
            console.log(response.data); // Handle the response from the API
            const newDocId = response.data.documentId
            console.log("newDocID", newDocId)
// setDocID(newDocId)
// console.log("DocID", docID)
            // Store the response in a state variable
            setApiResponse(response.data);
            return newDocId
        } catch (error) {
            console.error(error); // Handle the error
        }
    };

    const storeVC = async (verifiableCredential) => {
        try {

            const usernameMarriageLicenseReq = 
            `${(partner1Data.partner1_firstName || partner1Data.firstName || '').trim()} ${(partner1Data.partner1_lastName || partner1Data.lastName || '').trim()} & ${(partner2Data.partner2_firstName || partner2Data.firstName || '').trim()} ${(partner2Data.partner2_lastName || partner2Data.lastName || '').trim()}`.replace(/\s+/g, ' ').trim();
        
console.log("this is username:", usernameMarriageLicenseReq);

         const payload = {
                company: usernameMarriageLicenseReq,
                type: 'RequestMarriageLicenseVc',
                vc: verifiableCredential,
            };
            console.log("this is store vc payload:", payload)

            const config = {
                method: 'post',
                //url: 'https://publicdb.myearth.id/api/vc/storeVC',
                url: 'https://publicdb.myearth.id/api/vcnew/store',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(payload),
            };

            const response = await axios.request(config);
            console.log('Store VC response:', response.data);
            const newVcId = response.data.vcId
            setReferenceNo(newVcId)
            console.log("newVcID", newVcId)
            // setVcID(newVcId)
            // console.log("VCID", vcID)
            return newVcId;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };


    const verifyVC = async (credential) => {
        try {

            //const signature = await createUserSignature();

            const data = { "credentials": credential }
            console.log(data)
            const config = {
                method: 'post',
                url: ssiBaseUrl + "/api/verifier/verify?zpkValidation=false",
                headers: {
                    'X-API-KEY': authorizationKey,
                    'publicKey': profileData.userDid,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            };

            const response = await axios.request(config);
            console.log(JSON.stringify(response.data));

            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    // const handleQRCodeClick = async () => {
    //     const qrCodeCanvas = await toPng(<QRCode value={qrCodeUrl} />); // Convert QRCode component to canvas image
    
    //     Swal.fire({
    //       title: 'QR Code',
    //       html: `<div class="qrcode-container"><img src="${qrCodeCanvas}" alt="QR Code" /></div>`,
    //       showCancelButton: false,
    //       showConfirmButton: false,
    //       allowOutsideClick: true,
    //     });
    //   };

    const handleVerifyDoc = async (credential) => {
        // Display 'Verifying...' Swal alert
        Swal.fire({
            title: 'Verifying...',
            text: 'Please wait while the document is being verified.',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });

        try {
            // Perform the verification process
            const response = await verifyVC(credential); // Replace 'verifyVC()' with the actual verification function

            // Hide the 'Verifying...' Swal alert
            Swal.close();

            // Show success Swal alert if verification is successful
            if (response.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Verification Successful',
                    text: 'The document has been verified successfully.',
                });
            } else {
                // Show error Swal alert if verification fails
                Swal.fire({
                    icon: 'error',
                    title: 'Verification Failed',
                    text: 'The document verification failed.',
                });
            }

            // Return the verification result
            return response;
        } catch (error) {
            // Hide the 'Verifying...' Swal alert
            Swal.close();

            // Show error Swal alert if an error occurs during verification
            Swal.fire({
                icon: 'error',
                title: 'Verification Error',
                text: 'An error occurred during the document verification.',
            });

            // Throw the error to be caught by the calling function
            throw error;
        }
    };

// After the VC is stored successfully, call the following API to associate the VC with the uploaded document
const associateVcWithDocument = async (documentId, vcId) => {
    try {
      const response = await axios.post(`https://publicdb.myearth.id/api/docnew/associate-vc/${documentId}`, {
        vcId: vcId,
      });
      console.log(response.data.message);
    } catch (error) {
      console.error(error);
    }
  };

    const handleDocumentView = (document) => {
        setSelectedDocument(document);
    };

    const handleLogout = () => {
        window.location.href = "/";
    }


    const handleSelectCertificate = (certificateName) => {
        setSelectedCertificate(certificateName);
        //setShowSendRequest(true);
      };

const handleApplyPageNav = () => {
    window.location.href = "/apply-marriage-certificate";
}
      

  const handleDropdownChange = (event) => {
    setSelectedDropCertificate(event.target.value);
  };

        const [formData, setFormData] = useState({});
        const [sections, setSections] = useState({
            partner1: false,
            partner1_info: false,
            partner1_parents: false,
            partner1_address: false,
            partner1_identity: false,
            partner1_statistics: false,
            partner2: false,
            partner2_info: false,
            partner2_parents: false,
            partner2_address: false,
            partner2_identity: false,
            partner2_statistics: false,
        });
    
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });
        };

         // Handle form input changes
    const handleInputChange = (e, partner) => {
        const { name, value } = e.target;
        if (partner == "partner1") {
            setPartner1Data((prevData) => ({ ...prevData, [name]: value }));
        } else if (partner == "partner2"){
            setPartner2Data((prevData) => ({ ...prevData, [name]: value }));
        }
    };
    
        const toggleSection = (section) => {
            setSections({ ...sections, [section]: !sections[section] });
        };
    
        const handleSubmit = async (e) => {
            e.preventDefault();
        
            if (!partner1KYCComplete || !partner2KYCComplete) {
                Swal.fire({
                    title: "Error",
                    text: "Both partners must complete KYC before submitting!",
                    icon: "error",
                });
                return;
            }
        
            console.log("Submitting Data:", { partner1Data, partner2Data });
        
            Swal.fire({
                title: "Processing...",
                text: "Submitting your Marriage License Request...",
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });
        
            try {
                // Clean data to keep only necessary fields
        const filteredPartner1 = cleanPartnerData(partner1Data, "partner1_");
        const filteredPartner2 = cleanPartnerData(partner2Data, "partner2_");

        console.log("Filtered Partner 1 Data:", filteredPartner1);
        console.log("Filtered Partner 2 Data:", filteredPartner2);

                const reqCertificateVC = await createReqCertVC(filteredPartner1, filteredPartner2);
        
                if (reqCertificateVC) {
                    setShowPartner1Data(true);
                    setShowPartner2Data(true);
                    Swal.fire({
                        title: "Success",
                        text: "Marriage License Request Submitted!",
                        icon: "success",
                    });
                } else {
                    Swal.fire({
                        title: "Failed",
                        text: "Marriage License Request could not be submitted. Please try again later.",
                        icon: "error",
                    });
                }
            } catch (error) {
                console.error("Submission Error:", error);
                Swal.fire({
                    title: "Error",
                    text: "An unexpected error occurred. Please try again later.",
                    icon: "error",
                });
            }
        };
        
    
  
        const [formDataCert, setFormDataCert] = useState({});
      
      const handleChangeCert = (e) => {
          const { name, value } = e.target;
          setFormDataCert({ ...formDataCert, [name]: value });
      };
  
      const handleSubmitCert = (e) => {
          e.preventDefault();
          console.log('Submitted Data:', formData);
          alert('Marriage License Request Submitted!');
      };

      const handleManualAdd = (partner) => {
        console.log(`🟢 handleManualAdd called for: ${partner}`);
    
        if (partner === "Partner1") {
            console.log("✅ Setting partner1KYCComplete to true");
            setPartner1KYCComplete(true);
        } else if (partner === "Partner2") {
            console.log("✅ Setting partner2KYCComplete to true");
            setPartner2KYCComplete(true);
        } else {
            console.warn("⚠️ Unknown partner type received!");
        }
    };
    
    
    const cleanPartnerData = (data, partnerPrefix) => {
        return {
            [`${partnerPrefix}firstName`]: data[`${partnerPrefix}firstName`] || data.firstName || undefined,
            [`${partnerPrefix}lastName`]: data[`${partnerPrefix}lastName`] || data.lastName || undefined,
            [`${partnerPrefix}gender`]: data[`${partnerPrefix}gender`] || data.gender || undefined,
            [`${partnerPrefix}dateOfBirth`]: data[`${partnerPrefix}dateOfBirth`] || data.dateOfBirth || undefined,
            [`${partnerPrefix}email`]: data[`${partnerPrefix}email`] || data.email || undefined,
            [`${partnerPrefix}address`]: data[`${partnerPrefix}address`] || undefined,
            [`${partnerPrefix}city`]: data[`${partnerPrefix}city`] || undefined,
            [`${partnerPrefix}state`]: data[`${partnerPrefix}state`] || undefined,
            [`${partnerPrefix}zip`]: data[`${partnerPrefix}zip`] || undefined,
            [`${partnerPrefix}idType`]: data[`${partnerPrefix}idType`] || data.type || undefined,
            [`${partnerPrefix}idNumber`]: data[`${partnerPrefix}idNumber`] || data.number || undefined,
            [`${partnerPrefix}marriageHistory`]: data[`${partnerPrefix}marriageHistory`] || undefined,
            [`${partnerPrefix}education`]: data[`${partnerPrefix}education`] || undefined,
        };
    };
    

  return (
    <>
      <header className="navbarNew">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <nav>
          <ul>
            <li><a href="#">Government</a></li>
            <li><a href="#">Departments</a></li>
            <li><a href="#">Residences</a></li>
            <li><a href="#">Business</a></li>
           
          </ul>
          
        </nav>
      </header>
      <div className="social-bar">
        <a href="#"><FontAwesomeIcon className='soBarIcon' icon={faFacebook}/></a>
        <a href="#"><FontAwesomeIcon className='soBarIcon' icon={faTwitter}/></a>
        <a href="#"><FontAwesomeIcon className='soBarIcon' icon={faLinkedinIn}/></a>
      </div>
      <div className="applyCertSection">
        <div className='cert-titlemc'>Request for Marriage License</div>
        <div className="partner-container row">
        <div className='forPartner1 col-md-6'>
       
            <h3 className='section-title' 
            style={{
     width: "100vw",  // Ensures full viewport width
    maxWidth: "100%",
    display: "flex", 
    flexDirection: "column", 
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
}}>Partner 1</h3>
           

{!partner1KYCComplete && (
<KycFlow partner="Partner1" onComplete={() => handleKYCFlowComplete("Partner1")} onManualAdd={handleManualAdd} />
)}


{partner1KYCComplete && !showPartner1Data && (
 <div>
 <div className="section-content" style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px" }}>
     <label style={{ fontWeight: "bold", color: "#333" }}>First Name:</label>
     <div className="input-container">
     <input 
         name="partner1_firstName" 
         placeholder="First Name" 
         value={partner1Data.partner1_firstName || partner1Data.firstName || ""} 
         onChange={(e) => handleInputChange(e, "partner1")} 
         required 
         className='validated-input'
         //style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px" }}
     />
{!partner1Data.partner1_firstName && partner1Data.firstName && partner1Data.firstName.trim() !== "" && (
        <FontAwesomeIcon icon={faCircleCheck} className="input-icon" />
    )}
</div>

     <label style={{ fontWeight: "bold", color: "#333" }}>Last Name:</label>
     <div className="input-container">
     <input 
         name="partner1_lastName" 
         placeholder="Last Name" 
         value={partner1Data.partner1_lastName|| partner1Data.lastName || ""} 
         onChange={(e) => handleInputChange(e, "partner1")} 
         required 
         className='validated-input'
        // style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px" }}
     />
     {!partner1Data.partner1_lasttName && partner1Data.lastName && partner1Data.lastName.trim() !== "" && (
        <FontAwesomeIcon icon={faCircleCheck} className="input-icon" />
    )}
</div>

     <label style={{ fontWeight: "bold", color: "#333" }}>Email:</label>
     <div className="input-container">
     <input 
         name="partner1_email" 
         value={partner1Data.partner1_email || partner1Data.email || ""} 
         type="email" 
         placeholder="Email" 
         onChange={(e) => handleInputChange(e, "partner1")} 
          className='validated-input'
        // style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px" }}
     />
          {!partner1Data.partner1_email && partner1Data.email && partner1Data.email.trim() !== "" && (
        <FontAwesomeIcon icon={faCircleCheck} className="input-icon" />
    )}
</div>

     <label style={{ fontWeight: "bold", color: "#333" }}>Surname at Birth:</label>
     <div className="input-container">
     <input 
         name="partner1_birthSurname" 
         placeholder="Surname at Birth" 
         onChange={(e) => handleInputChange(e, "partner1")} 
         required 
          className='validated-input'
        // style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px" }}
     />
     </div>

     <label style={{ fontWeight: "bold", color: "#333" }}>Date of Birth:</label>
     <div className="input-container">
     <input 
         name="partner1_dateOfBirth" 
         value={partner1Data.partner1_dateOfBirth || partner1Data.dateOfBirth || ""} 
         placeholder="Date of Birth" 
         type="date" 
         onChange={(e) => handleInputChange(e, "partner1")} 
         required 
          className='validated-input'
        // style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px" }}
     />
               {!partner1Data.partner1_dateOfBirth && partner1Data.dateOfBirth && partner1Data.dateOfBirth.trim() !== "" && (
        <FontAwesomeIcon icon={faCircleCheck} className="input-icon" />
    )}
</div>


     <label style={{ fontWeight: "bold", color: "#333" }}>Gender:</label>
     <div className="input-container">
     <select 
         name="partner1_gender" 
         value={partner1Data.partner1_gender || partner1Data.gender || ""} 
         onChange={(e) => handleInputChange(e, "partner1")} 
         required
          className='validated-input'
        // style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px", backgroundColor: "#fff" }}
     >
         <option value="">Select Gender</option>
         <option value="M">Male</option>
         <option value="F">Female</option>
         <option value="O">Other</option>
     </select>
     {!partner1Data.partner1_gender && partner1Data.gender && partner1Data.gender.trim() !== "" && (
        <FontAwesomeIcon icon={faCircleCheck} className="input-icon" />
    )}
</div>

 </div>

 <div className="section-content" style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px" }}>
     <label style={{ fontWeight: "bold", color: "#333" }}>Street Address:</label>
     <div className="input-container">
     <input 
         name="partner1_address" 
         placeholder="Street Address" 
         onChange={(e) => handleInputChange(e, "partner1")} 
         required 
          className='validated-input'
        // style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px" }}
     />
     </div>

     <label style={{ fontWeight: "bold", color: "#333" }}>City:</label>
     <div className="input-container">
     <input 
         name="partner1_city" 
         placeholder="City" 
         onChange={(e) => handleInputChange(e, "partner1")} 
         required 
          className='validated-input'
        // style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px" }}
     />
     </div>

     <label style={{ fontWeight: "bold", color: "#333" }}>State:</label>
     <div className="input-container">
     <input 
         name="partner1_state" 
         placeholder="State" 
         onChange={(e) => handleInputChange(e, "partner1")} 
         required 
          className='validated-input'
        // style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px" }}
     />
     </div>

     <label style={{ fontWeight: "bold", color: "#333" }}>ZIP Code:</label>
     <div className="input-container">
     <input 
         name="partner1_zip" 
         placeholder="ZIP Code" 
         onChange={(e) => handleInputChange(e, "partner1")} 
         required 
          className='validated-input'
        // style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px" }}
     />
     </div>
     <label style={{ fontWeight: "bold", color: "#333" }}>Country:</label>
     <div className="input-container">
     <input 
         name="partner1_country" 
         placeholder="Country" 
         value={partner1Data.partner1_country || partner1Data.country || ""} 
         onChange={(e) => handleInputChange(e, "partner1")} 
         required 
          className='validated-input'
        // style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px" }}
     />      
     {!partner1Data.partner1_country && partner1Data.country && partner1Data.country.trim() !== "" && (
        <FontAwesomeIcon icon={faCircleCheck} className="input-icon" />
    )}
</div>            
 </div>

 <div className="section-content" style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px" }}>
     <label style={{ fontWeight: "bold", color: "#333" }}>ID Type:</label>
     <div className="input-container">
     <input 
         name="partner1_idType" 
         placeholder="ID Type" 
         value={partner1Data.partner1_idType || partner1Data.type || ""} 
         onChange={(e) => handleInputChange(e, "partner1")} 
         required 
          className='validated-input'
        // style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px" }}
     />      
     {!partner1Data.partner1_idType && partner1Data.type && partner1Data.type.trim() !== "" && (
        <FontAwesomeIcon icon={faCircleCheck} className="input-icon" />
    )}
</div>                          

     <label style={{ fontWeight: "bold", color: "#333" }}>ID Number:</label>
     <div className="input-container">
     <input 
         name="partner1_idNumber" 
         placeholder="Enter ID Number" 
         value={ partner1Data.partner1_idNumber || partner1Data.number || ""} 
         onChange={(e) => handleInputChange(e, "partner1")} 
         required 
          className='validated-input'
        // style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px" }}
     />
     {!partner1Data.partner1_idNumber && partner1Data.number && partner1Data.number.trim() !== "" && (
        <FontAwesomeIcon icon={faCircleCheck} className="input-icon" />
    )}
</div>
 </div>

 <div className="section-content" style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px" }}>
     <label style={{ fontWeight: "bold", color: "#333" }}>Have you married before?</label>
     <div className="input-container">
     <select 
         name="partner1_marriageHistory" 
         onChange={(e) => handleInputChange(e, "partner1")} 
         required
          className='validated-input'
        // style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px", backgroundColor: "#fff" }}
     >
         <option value="">Select an option</option>
         <option value="yes">Yes</option>
         <option value="no">No</option>
     </select>
</div>
     <label style={{ fontWeight: "bold", color: "#333" }}>Highest level of education completed:</label>
     <div className="input-container">
     <select 
         name="partner1_education" 
         onChange={(e) => handleInputChange(e, "partner1")} 
         required
          className='validated-input'
        // style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px", backgroundColor: "#fff" }}
     >
         <option value="">Select Education Level</option>
         <option value="highschool">High School</option>
         <option value="bachelor">Bachelor's</option>
         <option value="master">Master's</option>
         <option value="phd">PhD</option>
     </select>
     </div>
 </div>
</div>

                   
        )}

{/* Show Partner 1 Data */}
{partner1KYCComplete && showPartner1Data &&(
    <div className="partner-card">
        {/* <h3>👤 Partner 1 Details</h3> */}
        <div className="partner-info">
            <p><strong>First Name:</strong> {partner1Data.partner1_firstName || partner1Data.firstName || "N/A"}</p>
            <p><strong>Last Name:</strong> {partner1Data.partner1_lastName || partner1Data.lastName || "N/A"}</p>
            <p><strong>Gender:</strong> {partner1Data.partner1_gender || partner1Data.gender || "N/A"}</p>
            <p><strong>Date of Birth:</strong> {partner1Data.partner1_dateOfBirth || partner1Data.dateOfBirth || "N/A"}</p>
            <p><strong>Email:</strong> {partner1Data.partner1_email || partner1Data.email || "N/A"}</p>
            {/* <p><strong>Father’s Name:</strong> {partner1Data.partner1_father || "N/A"}</p>
            <p><strong>Mother’s Name:</strong> {partner1Data.partner1_mother || "N/A"}</p> */}
            <p><strong>Address:</strong> {partner1Data.partner1_address || "N/A"}</p>
            <p><strong>City:</strong> {partner1Data.partner1_city || "N/A"}</p>
            <p><strong>State:</strong> {partner1Data.partner1_state || "N/A"}</p>
            <p><strong>ZIP Code:</strong> {partner1Data.partner1_zip || "N/A"}</p>
            <p><strong>ID Type:</strong> {partner1Data.partner1_idType || partner1Data.type || "N/A"}</p>
            <p><strong>ID Number:</strong> {partner1Data.partner1_idNumber || partner1Data.number || "N/A"}</p>
            <p><strong>Marriage History:</strong> {partner1Data.partner1_marriageHistory || "N/A"}</p>
            <p><strong>Education:</strong> {partner1Data.partner1_education || "N/A"}</p>
        </div>
    </div>
)}

</div>

       

        <div className='forPartner2 col-md-6'>
        <h3 className='section-title' 
            style={{
     width: "100vw",  // Ensures full viewport width
    maxWidth: "100%",
    display: "flex", 
    flexDirection: "column", 
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
}}>Partner 2</h3>
{!partner2KYCComplete && (
    <KycFlow partner="Partner2" onComplete={() => handleKYCFlowComplete("Partner2")} onManualAdd={handleManualAdd}/>
)

}

{partner2KYCComplete && !showPartner2Data && (
 <div>
 <div className="section-content" style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px" }}>
     <label style={{ fontWeight: "bold", color: "#333" }}>First Name:</label>
     <div className="input-container">
     <input 
         name="partner2_firstName" 
         placeholder="First Name" 
         value={partner2Data.partner2_firstName || partner2Data.firstName || ""} 
         onChange={(e) => handleInputChange(e, "partner2")} 
         required 
         className='validated-input'
     />
{!partner2Data.partner2_firstName && partner2Data.firstName && partner2Data.firstName.trim() !== "" && (
        <FontAwesomeIcon icon={faCircleCheck} className="input-icon" />
    )}
</div>

     <label style={{ fontWeight: "bold", color: "#333" }}>Last Name:</label>
     <div className="input-container">
     <input 
         name="partner2_lastName" 
         placeholder="Last Name" 
         value={partner2Data.partner2_lastName || partner2Data.lastName || ""} 
         onChange={(e) => handleInputChange(e, "partner2")} 
         required 
         className='validated-input'
     />
     {!partner2Data.partner2_lastName && partner2Data.lastName && partner2Data.lastName.trim() !== "" && (
        <FontAwesomeIcon icon={faCircleCheck} className="input-icon" />
    )}
</div>

     <label style={{ fontWeight: "bold", color: "#333" }}>Email:</label>
     <div className="input-container">
     <input 
         name="partner2_email" 
         value={partner2Data.partner2_email || partner2Data.email || ""} 
         type="email" 
         placeholder="Email" 
         onChange={(e) => handleInputChange(e, "partner2")} 
          className='validated-input'
     />
          {!partner2Data.partner2_email && partner2Data.email && partner2Data.email.trim() !== "" && (
        <FontAwesomeIcon icon={faCircleCheck} className="input-icon" />
    )}
</div>

     <label style={{ fontWeight: "bold", color: "#333" }}>Surname at Birth:</label>
     <div className="input-container">
     <input 
         name="partner2_birthSurname" 
         placeholder="Surname at Birth" 
         onChange={(e) => handleInputChange(e, "partner2")} 
         required 
          className='validated-input'
     />
     </div>

     <label style={{ fontWeight: "bold", color: "#333" }}>Date of Birth:</label>
     <div className="input-container">
     <input 
         name="partner2_dateOfBirth" 
         value={partner2Data.partner2_dateOfBirth || partner2Data.dateOfBirth || ""} 
         placeholder="Date of Birth" 
         type="date" 
         onChange={(e) => handleInputChange(e, "partner2")} 
         required 
          className='validated-input'
     />
               {!partner2Data.partner2_dateOfBirth && partner2Data.dateOfBirth && partner2Data.dateOfBirth.trim() !== "" && (
        <FontAwesomeIcon icon={faCircleCheck} className="input-icon" />
    )}
</div>

     <label style={{ fontWeight: "bold", color: "#333" }}>Gender:</label>
     <div className="input-container">
     <select 
         name="partner2_gender" 
         value={partner2Data.partner2_gender || partner2Data.gender || ""} 
         onChange={(e) => handleInputChange(e, "partner2")} 
         required
          className='validated-input'
     >
         <option value="">Select Gender</option>
         <option value="M">Male</option>
         <option value="F">Female</option>
         <option value="O">Other</option>
     </select>
     {!partner2Data.partner2_gender && partner2Data.gender && partner2Data.gender.trim() !== "" && (
        <FontAwesomeIcon icon={faCircleCheck} className="input-icon" />
    )}
</div>

 </div>

 <div className="section-content" style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px" }}>
     <label style={{ fontWeight: "bold", color: "#333" }}>Street Address:</label>
     <div className="input-container">
     <input 
         name="partner2_address" 
         placeholder="Street Address" 
         onChange={(e) => handleInputChange(e, "partner2")} 
         required 
          className='validated-input'
     />
     </div>

     <label style={{ fontWeight: "bold", color: "#333" }}>City:</label>
     <div className="input-container">
     <input 
         name="partner2_city" 
         placeholder="City" 
         onChange={(e) => handleInputChange(e, "partner2")} 
         required 
          className='validated-input'
     />
     </div>

     <label style={{ fontWeight: "bold", color: "#333" }}>State:</label>
     <div className="input-container">
     <input 
         name="partner2_state" 
         placeholder="State" 
         onChange={(e) => handleInputChange(e, "partner2")} 
         required 
          className='validated-input'
     />
     </div>

     <label style={{ fontWeight: "bold", color: "#333" }}>ZIP Code:</label>
     <div className="input-container">
     <input 
         name="partner2_zip" 
         placeholder="ZIP Code" 
         onChange={(e) => handleInputChange(e, "partner2")} 
         required 
          className='validated-input'
     />
     </div>

     <label style={{ fontWeight: "bold", color: "#333" }}>Country:</label>
     <div className="input-container">
     <input 
         name="partner2_country" 
         placeholder="Country" 
         value={partner2Data.partner2_country || partner2Data.country || ""} 
         onChange={(e) => handleInputChange(e, "partner2")} 
         required 
          className='validated-input'
     />      
     {!partner2Data.partner2_country && partner2Data.country && partner2Data.country.trim() !== "" && (
        <FontAwesomeIcon icon={faCircleCheck} className="input-icon" />
    )}
</div>            
 </div>

 <div className="section-content" style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px" }}>
     <label style={{ fontWeight: "bold", color: "#333" }}>ID Type:</label>
     <div className="input-container">
     <input 
         name="partner2_idType" 
         placeholder="ID Type" 
         value={partner2Data.partner2_idType || partner2Data.type || ""} 
         onChange={(e) => handleInputChange(e, "partner2")} 
         required 
          className='validated-input'
     />      
     {!partner2Data.partner2_idType && partner2Data.type && partner2Data.type.trim() !== "" && (
        <FontAwesomeIcon icon={faCircleCheck} className="input-icon" />
    )}
</div>                          

     <label style={{ fontWeight: "bold", color: "#333" }}>ID Number:</label>
     <div className="input-container">
     <input 
         name="partner2_idNumber" 
         placeholder="Enter ID Number" 
         value={ partner2Data.partner2_idNumber || partner2Data.number || ""} 
         onChange={(e) => handleInputChange(e, "partner2")} 
         required 
          className='validated-input'
     />
     {!partner2Data.partner2_idNumber && partner2Data.number && partner2Data.number.trim() !== "" && (
        <FontAwesomeIcon icon={faCircleCheck} className="input-icon" />
    )}
</div>
 </div>

 <div className="section-content" style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px" }}>
     <label style={{ fontWeight: "bold", color: "#333" }}>Have you married before?</label>
     <div className="input-container">
     <select 
         name="partner2_marriageHistory" 
         onChange={(e) => handleInputChange(e, "partner2")} 
         required
          className='validated-input'
        // style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px", backgroundColor: "#fff" }}
     >
         <option value="">Select an option</option>
         <option value="yes">Yes</option>
         <option value="no">No</option>
     </select>
</div>
     <label style={{ fontWeight: "bold", color: "#333" }}>Highest level of education completed:</label>
     <div className="input-container">
     <select 
         name="partner2_education" 
         onChange={(e) => handleInputChange(e, "partner2")} 
         required
          className='validated-input'
        // style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px", backgroundColor: "#fff" }}
     >
         <option value="">Select Education Level</option>
         <option value="highschool">High School</option>
         <option value="bachelor">Bachelor's</option>
         <option value="master">Master's</option>
         <option value="phd">PhD</option>
     </select>
     </div>
     
 </div>
</div>
)}


{/* Show Partner 2 Data */}
{partner2KYCComplete && showPartner2Data &&(
    <div className="partner-card">
        {/* <h3>👤 Partner 2 Details</h3> */}
        <div className="partner-info">
            <p><strong>First Name:</strong> {partner2Data.partner2_firstName || partner2Data.firstName || "N/A"}</p>
            <p><strong>Last Name:</strong> {partner2Data.partner2_lastName || partner2Data.lastName || "N/A"}</p>
            <p><strong>Gender:</strong> {partner2Data.partner2_gender || partner2Data.gender || "N/A"}</p>
            <p><strong>Date of Birth:</strong> {partner2Data.partner2_dateOfBirth || partner2Data.dateOfBirth || "N/A"}</p>
            <p><strong>Email:</strong> {partner2Data.partner2_email || partner2Data.email || "N/A"}</p>
            <p><strong>Address:</strong> {partner2Data.partner2_address || "N/A"}</p>
            <p><strong>City:</strong> {partner2Data.partner2_city || "N/A"}</p>
            <p><strong>State:</strong> {partner2Data.partner2_state || "N/A"}</p>
            <p><strong>ZIP Code:</strong> {partner2Data.partner2_zip || "N/A"}</p>
            <p><strong>ID Type:</strong> {partner2Data.partner2_idType || partner2Data.type || "N/A"}</p>
            <p><strong>ID Number:</strong> {partner2Data.partner2_idNumber || partner2Data.number || "N/A"}</p>
            <p><strong>Marriage History:</strong> {partner2Data.partner2_marriageHistory || "N/A"}</p>
            <p><strong>Education:</strong> {partner2Data.partner2_education || "N/A"}</p>
        </div>
    </div>
)}

</div>

{partner2KYCComplete && showPartner2Data && partner1KYCComplete && showPartner1Data &&  (
    <div 
        style={{
            backgroundColor: "#d4edda", 
            color: "#155724", 
            padding: "15px", 
            borderRadius: "5px", 
            textAlign: "center", 
            fontSize: "18px", 
            fontWeight: "bold", 
            marginTop: "20px", 
            border: "1px solid #c3e6cb",
            width: "100%",
            maxWidth: "600px",
            margin: "20px auto"
        }}
    >
        🎉 Your marriage certificate request has been successfully submitted! We will notify you once it is processed.
        <br/>
        Reference Number: {referenceNo}
    </div>
)}
      </div> 
      
     
   


{ !showPartner1Data && !showPartner2Data && (
 <button
 type="submit"
 className="submit-buttonmc"
 disabled={!partner1KYCComplete || !partner2KYCComplete}
 onClick={handleSubmit}
>
 Submit Request
</button>
)

}
                       
                  
      </div>
      
      
     
    </>
  )
}

export default ApplyMarriage

import { faChain, faCross, faEye, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import './admin.css';
import axios from 'axios';

const ssiBaseUrl = "https://ssi-test.myearth.id";
const authorizationKey = "01a41742-aa8e-4dd6-8c71-d577ac7d463c";

const privateKey = "6mf4ZKu2ts0V4Dr0sS+YbXYveZOyDezzTJUqa5Ls29BHN4jBTY5we/WW0CgmuNGb3PBvdqqJPhDF2YCMnF4faw==";
const publicKey = "RzeIwU2OcHv1ltAoJrjRm9zwb3aqiT4QxdmAjJxeH2s=";
const issuerDID = "did:earthid:testnet:2utZGEuzWe8ds5Jaxd7KdMD7bwKMm8QiRKnS2t4Q8Dve;earthid:testnet:fid=0.0.15057499";
const userDID = "did:earthid:testnet:9WzSyPSBkbUQVktjGxmvKAzbTNJCs1PFqo2fQ6BnuHiE;earthid:testnet:fid=0.0.15057499";

const UserCertTable = () => {
  const [userCerts, setUserCerts] = useState([]);
  const [statusList, setStatusList] = useState({});

  useEffect(() => {
    fetchUserCertificates();
  }, []);

  const fetchUserCertificates = async () => {
    try {
      const response = await fetch('https://publicdb.myearth.id/api/certificate/getAllUserCerts');
      const data = await response.json();

      const filteredData = data.filter(userCert => userCert.certName === "Marriage License");

      const userCertGroups = filteredData.reduce((groups, userCert) => {
        if (!groups[userCert.userName]) {
          groups[userCert.userName] = [];
        }
        if (!groups[userCert.userName].includes(userCert.certName)) {
          groups[userCert.userName].push(userCert.certName);
        }
        return groups;
      }, {});

      console.log('Filtered Marriage License Data:', userCertGroups);

      const distinctUserCerts = Object.keys(userCertGroups).map(userName => ({
        userName,
        certNames: userCertGroups[userName]
      }));

      setUserCerts(distinctUserCerts);

       // 🔹 Initialize status for each user
       const initialStatus = {};
       distinctUserCerts.forEach(user => {
         initialStatus[user.userName] = "Pending"; // Default status
       });
       setStatusList(initialStatus);
 
       // 🔹 Check status for each user
       distinctUserCerts.forEach(user => checkVcStatus(user.userName));
    } catch (error) {
      console.error('Error fetching user certs:', error);
    }
  };

  // 🔹 Check if a VC exists for a user
  const checkVcStatus = async (userName) => {
    try {
      const prefix = 'MarriageLicense';
      const userNameWithPrefix = `${prefix}${userName}`;

      const fetchVCResponse = await fetch(`https://publicdb.myearth.id/api/vcnew/company/${userNameWithPrefix}/CertificateVc`);
      const vcData = await fetchVCResponse.json();

      setStatusList(prevStatusList => ({
        ...prevStatusList,
        [userName]: vcData.response.length > 0 ? "Issued" : "Revoked"
      }));
    } catch (error) {
      console.error("Error checking VC status:", error);
    }
  };

  const handleViewCertificate = (userName) => {
    window.open(`/v1/license/marriage/${userName}`, '_blank');
  };

  const handleRevokeCertificate = async (userName) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to revoke the license for this user. This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const prefix = 'MarriageLicense';
          const userNameWithPrefix = `${prefix}${userName}`;

          console.log('Fetching VCs for:', userNameWithPrefix);

          const fetchVCResponse = await fetch(`https://publicdb.myearth.id/api/vcnew/company/${userNameWithPrefix}/CertificateVc`);
          const vcData = await fetchVCResponse.json();

          if (vcData && vcData.response && vcData.response.length > 0) {
            const vcIDs = vcData.response.map(vc => vc._id); // Extract all VC IDs

            console.log('Found VC IDs:', vcIDs);

            // Delete each VC one by one
            const deletePromises = vcIDs.map(vcID =>
              fetch('https://publicdb.myearth.id/api/vcnew/delete', {
                method: "POST",
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ vcID }),
              })
            );

            // Wait for all deletions to complete
            const deleteResponses = await Promise.all(deletePromises);
            const deleteResults = await Promise.all(deleteResponses.map(res => res.json()));

            setStatusList(prevStatusList => ({
              ...prevStatusList,
              [userName]: "Revoked"
            }));
           
              Swal.fire("Revoked!", "The requested certificate has been revoked.", "success");
              setUserCerts(prevCerts => prevCerts.filter(cert => cert.userName !== userName));
           
          } else {
            Swal.fire("Error!", "No certificates found for this user.", "error");
          //  Swal.fire("Revoked!", "All certificates have been revoked.", "success");
            setUserCerts(prevCerts => prevCerts.filter(cert => cert.userName !== userName));
          }
        } catch (error) {
          console.error("Error revoking certificates:", error);
          Swal.fire("Error!", "Something went wrong. Try again.", "error");
        }
      }
    });
  };

  const verifyVC = async (credential) => {
    try {

        //const signature = await createUserSignature();

        const data = { "credentials": credential }
        console.log(data)
        const config = {
            method: 'post',
            url: ssiBaseUrl + "/api/verifier/verify?zpkValidation=false",
            headers: {
                'X-API-KEY': authorizationKey,
                'publicKey': publicKey,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        };

        const response = await axios.request(config);
        console.log(JSON.stringify(response.data));

        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

// 🔹 View Digital Credential and Verify
const handleViewDgtCertificate = async (userName) => {
  try {
    const prefix = 'MarriageLicense';
    const userNameWithPrefix = `${prefix}${userName}`;

    console.log('Fetching VCs for:', userNameWithPrefix);

    const fetchVCResponse = await fetch(`https://publicdb.myearth.id/api/vcnew/company/${userNameWithPrefix}/CertificateVc`);
    const vcData = await fetchVCResponse.json();

    if (!vcData || !vcData.response || vcData.response.length === 0) {
      Swal.fire({
        icon: "error",
        title: "No Data Found",
        text: "No Verifiable Credential found for this user.",
      });
      return;
    }

    const documentMetadata = vcData.response[0].vc;
    const formattedJson = JSON.stringify(documentMetadata, null, 2);

    Swal.fire({
      title:  ` <h3 style="color: #42145f; font-size: 22px; font-weight: bold; margin-bottom: 15px;">Digital Credential</h3>`,
     
      html: `
        <div style="text-align: left; padding: 15px; border-radius: 10px; background: #F8F9FA; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); font-size: 14px; white-space: pre-wrap; max-height: 500px; overflow-y: auto;">
            <pre style="white-space: pre-wrap; word-wrap: break-word; margin: 0; padding: 0;">${formattedJson}</pre>
        </div>
      `,
      showCancelButton: true,
      confirmButtonText: "Verify",
      cancelButtonText: "Close",
      confirmButtonColor: "#42145f",
      cancelButtonColor: "#6c757d",
      width: "800px",
      customClass: {
        popup: "swal-custom-popup",
        title: "swal-custom-title",
        htmlContainer: "swal-custom-html",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleVerifyDoc(documentMetadata);
      }
    });
  } catch (error) {
    console.error("Error fetching digital credential:", error);
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Could not retrieve digital credential.",
    });
  }
};


    const handleVerifyDoc = async (credential) => {
        // Display 'Verifying...' Swal alert
        Swal.fire({
            title: 'Verifying...',
            text: 'Please wait while the credential is being verified.',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });

        try {
            // Perform the verification process
            const response = await verifyVC(credential); // Replace 'verifyVC()' with the actual verification function

            // Hide the 'Verifying...' Swal alert
            Swal.close();

            // Show success Swal alert if verification is successful
            if (response.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Verification Successful',
                    text: 'The credential has been verified successfully.',
                });
            } else {
                // Show error Swal alert if verification fails
               Swal.fire({
                                   icon: 'error',
                                   title: 'Verification Failed',
                                   text: 'The document verification failed.',
                               });
            }

            // Return the verification result
            return response;
        } catch (error) {
            // Hide the 'Verifying...' Swal alert
            Swal.close();

            // Show error Swal alert if an error occurs during verification
            Swal.fire({
                           icon: 'error',
                           title: 'Verification Error',
                           text: 'An error occurred during the document verification.',
                       });

            // Throw the error to be caught by the calling function
            throw error;
        }
    };



  return (
    <div className='user-tablee'>
      <table className='user-table'>
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Partner Names</th>
            <th>Issued Certificate</th>
            <th>View</th>
            <th>Link</th>
            <th>Revoke</th>
             <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {userCerts.map((userCert, index) => {
            const status = statusList[userCert.userName] || "Pending";

            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{userCert.userName}</td>
                <td>Marriage License</td>
                <td>
                  {status !== "Revoked" && (
                    <FontAwesomeIcon
                      onClick={() => handleViewDgtCertificate(userCert.userName)}
                      className="eyeIcon"
                      icon={faEye}
                    />
                  )}
                </td>
                <td>
                  {status !== "Revoked" && (
                    <FontAwesomeIcon
                      onClick={() => handleViewCertificate(userCert.userName)}
                      className="eyeIcon"
                      icon={faChain}
                    />
                  )}
                </td>
                <td>
                  {status !== "Revoked" && (
                    <FontAwesomeIcon
                      onClick={() => handleRevokeCertificate(userCert.userName)}
                      className="eyeIcon red-icon"
                      icon={faTimes}
                    />
                  )}
                </td>
                <td className={`status-cell ${status.toLowerCase()}`}>
                  {status}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default UserCertTable;

import React, { Component } from "react";
import crypto from "crypto";
import data from "./api";
import { store } from "react-notifications-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const {
  listenForUserData,
  generateQrCodeNest,
  registerVendor,
} = data;

class KycFlow extends Component {
  state = {
    showSpinner: false,
    qrcode: false,
    response: false,
    showModal: false, // Controls modal visibility
    qrCodeData: "",
  };

  componentDidMount = async () => {
    if (localStorage.email != null) {
      this.setState({ loggedIn: true });
    }
  };

  openModal = () => {
    this.setState({ showModal: true });

    setTimeout(() => {
      const modalElement = document.getElementById("exampleModalKyc");
      if (modalElement) {
        const modalInstance = new window.bootstrap.Modal(modalElement);
        modalInstance.show();
      }
    }, 0);
  };

  closeModal = () => {
    this.setState({ showModal: false });

    setTimeout(() => {
      const modalElement = document.getElementById("exampleModalKyc");
      if (modalElement) {
        const modalInstance = new window.bootstrap.Modal(modalElement);
        modalInstance.hide();
      }

      // Remove Bootstrap modal backdrop
      const modalBackdrop = document.querySelector(".modal-backdrop");
      if (modalBackdrop) {
        modalBackdrop.remove();
      }

      document.body.classList.remove("modal-open");
    }, 0);
  };

  listenForSocket = () => {
    listenForUserData(async (err, res) => {
      let bytes = res.userDetails || res.newreq;
      sessionStorage.setItem("profileData", JSON.stringify(bytes));

      if (bytes === "denied" || bytes.permission === "denied") {
        this.closeModal();
      } else {
        this.setState({ response: true, showSpinner: false, loggedIn: true });
        this.closeModal();
      }
    });
  };

  QrCodeDataSDD = () => {
    registerVendor()
      .then((d) => {
        const timestamp = Date.now();
        const hash = crypto
          .createHmac("sha256", d.secretKey)
          .update(JSON.stringify(d.apiKey + "Rejolut@1008" + timestamp))
          .digest("hex");

        generateQrCodeNest(hash, d.apiKey, "Rejolut@1008", timestamp, "selectiveData")
          .then((data) => {
            this.listenForSocket();
            this.setState({ qrCodeData: data });
            this.openModal(); // Open modal when QR is generated
            this.props.onComplete();
          })
          .catch((err) => alert(err));
      })
      .catch((err) => alert(err));
  };

  handleManualAdd = () => {
    console.log(`🟠 "Add Manually" clicked for: ${this.props.partner}`);
    if (this.props.onManualAdd) {
      this.props.onManualAdd(this.props.partner);
    } else {
      console.warn("⚠️ onManualAdd function is not provided!");
    }
    this.closeModal();
  };

  render() {
    return (
      <>
        {/* 🔹 Button to trigger modal */}
        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <button
            type="button"
            className="kycBtn"
            onClick={this.QrCodeDataSDD}
            style={{
              padding: "7px 10px",
              width: "140px",
              backgroundColor: "#fff",
              color: "#80a1c1",
              border: "2px solid #80a1c1",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "16px",
            }}
          >
            Add Details
          </button>
        </div>

        {/* 🔹 Bootstrap Styled Modal */}
        <div className="modal fade" id="exampleModalKyc" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            {this.state.showModal && (
              <div className="modal-content">
                {/* 🔹 Modal Header */}
                <div className="close-button-containerr">
                  <h4 style={{ color: "black" }}>Scan with EarthID Wallet</h4>
                  <FontAwesomeIcon
                    className="close-buttonn"
                    data-dismiss="modal"
                    icon={faClose}
                    onClick={this.closeModal}
                  />
                </div>

                {/* 🔹 Modal Body */}
                <div className="modal-body">
                  <div className="pop-upbox">
                    {this.state.showSpinner && (
                      <div className="spinner-div">
                        <img className="spinner" src={require("./spinner.gif")} alt="Loading..." />
                      </div>
                    )}

                    {this.state.qrCodeData ? (
                      <div className="qr-container" style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                        <img src={this.state.qrCodeData} alt="QR Code" style={{ width: "150px", height: "150px" }} />
                      </div>
                    ) : (
                      <p style={{ textAlign: "center", color: "#888", fontSize: "14px" }}>Loading QR...</p>
                    )}

                    {/* 🔹 Add Manually Button */}
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                      <button
                        className="btn btn-primary p-btn"
                        onClick={this.handleManualAdd}
                        style={{
                          backgroundColor: "#80a1c1",
                          border: "none",
                          color: "white",
                          fontSize: "14px",
                          padding: "8px 15px",
                          borderRadius: "5px",
                          cursor: "pointer",
                          width: "150px",
                        }}
                      >
                        Add Manually
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default KycFlow;

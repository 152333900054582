import React, { useState } from "react";
import QRCode from "qrcode.react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import "./App.css"; // Ensure it uses the same styling as `LoginCert.js`

const AddtoWallet = ({ certUrl, onClose }) => {
  const [showModal, setShowModal] = useState(true);

  return (
    <div className="App">
      <div className="hero-section">
        <div className="shoppingCart"></div>

        {/* 🔹 Styled Modal */}
        <div
          className="modal fade show"
          id="exampleModalWallet"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block", background: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog" role="document">
            {showModal && (
              <div className="modal-content">
                {/* 🔹 Modal Header */}
                <div className="close-button-containerr">
                  <h4 style={{ color: "black",  textAlign: "center" }}>Scan to add to your wallet</h4>
                  <FontAwesomeIcon
                    className="close-buttonn"
                    style={{ cursor: "pointer", fontSize: "20px", color: "#000" }}
                    data-dismiss="modal"
                    icon={faClose}
                    onClick={() => {
                      setShowModal(false);
                      onClose();

                      // 🔹 Hide Bootstrap modal
                      const modalElement = document.getElementById("exampleModalWallet");
                      if (modalElement) {
                        const modalInstance = new window.bootstrap.Modal(modalElement);
                        modalInstance.hide();
                      }

                      // 🔹 Remove Bootstrap modal backdrop
                      const modalBackdrop = document.querySelector(".modal-backdrop");
                      if (modalBackdrop) {
                        modalBackdrop.remove();
                      }

                      document.body.classList.remove("modal-open");
                    }}
                  />
                </div>

                {/* 🔹 Modal Body */}
                <div className="modal-body">
                  <div style={{ padding: "10px", textAlign: "center" }}>
                    <QRCode value={certUrl} size={180} />
                    <p style={{ fontSize: "16px", marginTop: "30px", color: "#555" }}>
                      Scan this QR code with your wallet app to add the credential.
                    </p>
                  </div>
                </div>

                
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddtoWallet;

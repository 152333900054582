import React, { useState, useEffect } from "react";
import crypto from "crypto";
import data from "./api";
import { store } from "react-notifications-component";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const {
  listenForUserData,
  listenForServiceProviderResponse,
  generateQrCodeNest,
  registerVendor,
} = data;

const LoginCert = ({ onLoginSuccess }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [showQr, setShowQr] = useState(false);
  const [qrCodeData, setQrCodeData] = useState("");
  const [response, setResponse] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const mockCredentials = {
    username: "admin",
    password: "password123",
  };

  let showNotification = true;

  useEffect(() => {
    generateQrCodeDataAdmin();
  }, []);

  // 🔹 Generate QR Code
  const generateQrCodeDataAdmin = async () => {
    try {
      const d = await registerVendor();
      const timestamp = Date.now();
      const hash = crypto
        .createHmac("sha256", d.secretKey)
        .update(JSON.stringify(d.apiKey + "Rejolut@1008" + timestamp))
        .digest("hex");

      const data = await generateQrCodeNest(hash, d.apiKey, "Rejolut@1008", timestamp, "login");

      listenForSocketAdmin();

      setShowQr(true);
      setQrCodeData(data);
    } catch (err) {
      alert(err);
    }
  };

  // 🔹 Listen for User Data via WebSocket
  const listenForSocketAdmin = () => {
    listenForServiceProviderResponse(() => {
      setResponse(false);
      setShowSpinner(true);
    });

    listenForUserData((err, res) => {
      let bytes = res.userDetails || res.newreq;
      console.log("Received user data:", bytes);

      setShowQr(false);
      setShowSpinner(false);

      if (bytes === "denied" || bytes.permission === "denied") {
        userDenied();
      } else if (
        bytes.permission === "granted" ||
        bytes === "issuerLogin" ||
        bytes === "credentialsgenerated" ||
        bytes === "verifylogin" ||
        bytes === "success"
      ) {
        handleLoginSuccess();
      }
    });
  };

  // 🔹 Handle Successful Login
  const handleLoginSuccess = () => {
    setResponse(true);
    setShowSpinner(false);
    setShowModal(false);

    if (onLoginSuccess) {
      onLoginSuccess(true);
    }

    setTimeout(() => {
      const modalElement = document.getElementById("exampleModalLogin");
      if (modalElement) {
        const modalInstance = new window.bootstrap.Modal(modalElement);
        modalInstance.hide();
      }

      // 🔹 Remove Bootstrap modal backdrop manually
      const modalBackdrop = document.querySelector(".modal-backdrop");
      if (modalBackdrop) {
        modalBackdrop.remove();
      }

      document.body.classList.remove("modal-open");

      if (showNotification) {
        store.addNotification({
          title: "Success!",
          message: `Login Successful`,
          type: "success",
          insert: "top",
          container: "top-right",
          dismiss: { duration: 3000, onScreen: true },
        });
      }
    }, 0);
  };

  // 🔹 Handle User Denied Login
  const userDenied = () => {
    setResponse(false);
    setShowModal(false);
    setTimeout(() => {
      document.getElementById("exampleModalLogin").click();
      if (showNotification) {
        showNotification = false;
        store.addNotification({
          title: "Error!",
          message: `User Didn't Authorize!`,
          type: "danger",
          insert: "top",
          container: "top-right",
          dismiss: { duration: 3000, onScreen: true },
        });
      }
    }, 0);
  };

  // 🔹 Handle Manual Username & Password Login
  const handleManualLogin = () => {
    if (username === mockCredentials.username && password === mockCredentials.password) {
      handleLoginSuccess();
    } else {
      store.addNotification({
        title: "Error!",
        message: "Invalid Username or Password",
        type: "danger",
        insert: "top",
        container: "top-right",
        dismiss: { duration: 3000, onScreen: true },
      });
    }
  };

  // 🔹 QR Code Renderer
  const renderQrCode = () => (
    <div style={{ textAlign: "center", marginBottom: "15px" }}>
      <img
        src={qrCodeData}
        alt="QR Code"
        style={{
          width: "150px",
          height: "150px",
          borderRadius: "10px",
          padding: "10px",
          backgroundColor: "#fff",
        }}
      />
    </div>
  );

  return (
    <div className="App">
      <div className="hero-section">
        <div className="shoppingCart"></div>

        {/* 🔹 Updated Modal with Styling */}
        <div className="modal fade" id="exampleModalLogin" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            {showModal && (
              <div className="modal-content" >
                {/* 🔹 Modal Header */}
                <div className="close-button-containerr">
                  <h4 style={{ color: "black"}}>Scan me using your wallet</h4>
                  <FontAwesomeIcon
                    className="close-buttonn"
                    style={{ cursor: "pointer", fontSize: "20px", color: "#000" }}
                    data-dismiss="modal"
                    icon={faClose}
                    onClick={() => {
                      setShowModal(false);
                      const modalElement = document.getElementById("exampleModalLogin");
                      if (modalElement) {
                        const modalInstance = new window.bootstrap.Modal(modalElement);
                        modalInstance.hide();
                      }

                      // 🔹 Remove Bootstrap modal backdrop
                      const modalBackdrop = document.querySelector(".modal-backdrop");
                      if (modalBackdrop) {
                        modalBackdrop.remove();
                      }

                      document.body.classList.remove("modal-open");
                    }}
                  />
                </div>

                {/* 🔹 Modal Body */}
                <div className="modal-body">
                  <div style={{ padding: "10px" }}>
                    {showSpinner && (
                      <div className="spinner-div" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <img className="spinner" src={require("./spinner.gif")} alt="Loading..." />
                      </div>
                    )}

                    {showQr ? renderQrCode() : showSpinner ? <img className="spinner" src={require("./spinner.gif")} alt="Loading..." /> : null}

                    {/* 🔹 Manual Login Form */}
                    <div className="login-form" style={{ marginTop: "15px", textAlign: "center" }}>
                    <p style={{ fontWeight: "bold", marginBottom: "20px", marginTop: "20px" }}>OR</p>
                      <h5 style={{ fontWeight: "bold", marginBottom: "20px" }}>Manual Login</h5>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        style={{ marginBottom: "20px", padding: "8px", borderRadius: "5px" }}
                      />
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{ marginBottom: "15px", padding: "8px", borderRadius: "5px" }}
                      />
                      <button
                        className="btnn"
                        style={{ width: "80%", padding: "8px", backgroundColor:"#80a1c1", borderRadius: "5px", fontSize: "16px", fontWeight: "bold" }}
                        onClick={handleManualLogin}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginCert;
